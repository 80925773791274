import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { MiPortalPageWeb } from './componentes/web';
import { Provider as JotaiProvider } from "jotai";
import { MiPortalMovil } from './componentes/movil';

const MiPortalPage = () => {
    const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
    const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });
    return (
        <JotaiProvider>
            {esDesktop && <MiPortalPageWeb />}
            {esMovil && <MiPortalMovil />}
        </JotaiProvider>
    );
};

export default MiPortalPage;