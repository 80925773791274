import React from "react";
import { Header } from "./Header";
import { ListaDocumentos } from "./ListaDocumentos";
import { ModalFirmar } from "../ModalFirmar";
import { ModalMensajeAlerta } from "../ModalMensajeAlerta";
import { ModalSubirArchivo } from "../ModalSubirArchivo/ModalSubirArchivo";
import { ModalExito } from "../ModalExito";

const MisDocumentosMovil = () => {
	return (
		<React.Fragment>
			<Header />
			<ListaDocumentos />
			<ModalFirmar />
			<ModalMensajeAlerta />
			<ModalSubirArchivo />
			<ModalExito />
		</React.Fragment>
	);
};

export default MisDocumentosMovil;
