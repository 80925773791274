import React, { useEffect, useState } from "react";
import {
	Card, Avatar, Typography, List, Carousel, Tag, Button, Space, Image,
	Tooltip
 } from "antd";
import {
	HeartOutlined, LikeOutlined, UserOutlined,
	LeftOutlined, RightOutlined, SelectOutlined
} from "@ant-design/icons";
 import {
	FcPlanner
 } from "react-icons/fc";
import moment from "moment";
import "moment/locale/es";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "@emotion/styled";
import { useHistory } from "react-router-dom";
import { usePublicacion } from "../../componentes/web/hooks";
import LoginService from "services/LoginService";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { PublicacionService } from "services/comunicacion-cultura";
// import { PublicacionItem } from "../web/componentes/ListaPublicaciones/componentes/PublicacionItem";
const { Meta } = Card;
const { Text, Link, Ellipsis } = Typography;
// const { Ellipsis } = Typography;
const MiPortalMovil = () => {
	return (
		<Container>
			<SeccionPortal>
				<Perfil />
				<Cumpleanios />
				<Comunicaciones />
			</SeccionPortal>
		</Container>
	);
};

const Perfil = () => {
	const [informacion, setInformacion] = useState(undefined);
	useEffect(() => {
		const cargarInformacion = async () => {
			const response = await PortalColaboradorService.buscarColaborador();
			setInformacion(response);
		};

		cargarInformacion();
	}, []);
	return (
		<Card
			style={{
				borderRadius: "8px",
			}}
		>
			<Meta
				avatar={
					informacion?.urlFotoPersonalFirmado !== null ? (
						<Image src={informacion?.urlFotoPersonalFirmado} width={60} style={{ borderRadius: "50%" }} />
					) : (
						<Avatar size={60} icon={<UserOutlined />} />
					)
				}
				title={
					<div>
						<Text style={{ fontSize: "14px", color: "#666" }}>Hola,</Text>
						<br />
						<Text style={{ fontWeight: "bold", fontSize: "14px", color: "#333" }}>
							{LoginService.getUsuarioAutenticado().nombreCompleto}
						</Text>
					</div>
				}
			/>
		</Card>
	);
};

const Cumpleanios = () => { 
 moment.locale("es");
	const history = useHistory();
	
	const [cumpleanios, setCumpleanios] = useState([]);
	const [pagina, setPagina] = useState(0);
	const [totalPaginas, setTotalPaginas] = useState(0);

	useEffect(() => {
		const getCumpleanios = async () => {
			const respuesta = await PublicacionService.listarCumpleaniosPaginadoHoy({
				pagina: pagina,
				cantidad: 3,
			});
			console.log("Cumpleanios::", respuesta);
			setCumpleanios(respuesta.cumpleanios);
			setTotalPaginas(respuesta.totalPaginas); // Total de páginas desde el backend
		};

		getCumpleanios();
	}, [pagina]);

	const handleNext = () => {
		if (pagina < totalPaginas - 1) {
			setPagina((prev) => prev + 1);
		}
	};

	const handlePrev = () => {
		if (pagina > 0) {
			setPagina((prev) => prev - 1);
		}
	};

	const BirthdayCard = ({ id, nombreYApellidoPaterno, fechaNacimiento, urlFotoPersonal }) => (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			{/* Imagen de perfil con fondo de color */}
			<Tooltip title={nombreYApellidoPaterno} key={id}>
				{/* <div style={{ backgroundColor: "rgb(144 137 137)", borderRadius: "50%", padding: "1px" }}> */}
					{urlFotoPersonal !== null ? (
						// <Avatar
						// 	src={urlFotoPersonal}
						// 	style={{
						// 		width: "70px",
						// 		height: "85px",
						// 		borderRadius: "50%",
						// 	}}
					// />
					<Image src={urlFotoPersonal} width={80} style={{ borderRadius: "50%" }} preview={false}/>
					) : (
						<Avatar size={80} icon={<UserOutlined />} />
					)}
				{/* </div> */}
			</Tooltip>
		</div>
	);

	const onClickVerMas = () => {
		history.push("/comunicaciones-cultura/cumpleanios");
	};
	return (
		<div style={{ padding: "0px" }}>
			<Card
				title={
					<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								color: "black",
								fontWeight: "normal",
								fontSize: "14px",
							}}
						>
							<FcPlanner size="1.5em" style={{ marginRight: "0.5em" }} />
							<span>Cumpleaños Hoy</span>
						</div>
					</div>
				}
				extra={
					<Button type="link" icon={<SelectOutlined />} onClick={onClickVerMas}>
									Más
					</Button>
				}
				style={{
					// marginBottom: "16px",
					borderRadius: "8px",
					boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
					border: "1px solid #f0f0f0",
				}}

				
			>
				{/* Contenido principal */}
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "repeat(3, 1fr)",
						gap: "10px",
						width: "100%",
					}}
				>
					{cumpleanios.map((item, idx) => (
						<BirthdayCard key={idx} {...item} />
					))}
				</div>

				{/* Footer con flechas y mes actual */}
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						padding: "10px 20px",
						marginTop: "10px",
					}}
				>
					<button
						onClick={handlePrev}
						disabled={pagina === 0}
						style={{
							backgroundColor: "transparent",
							border: "none",
							cursor: pagina === 0 ? "not-allowed" : "pointer",
						}}
					>
						<LeftOutlined style={{ fontSize: "20px", color: pagina === 0 ? "#ccc" : "#333" }} />
					</button>

					<span style={{ fontWeight: "normal", fontSize: "14px" }}>
						{moment().format("D [de] MMMM YYYY")}
					</span>

					<button
						onClick={handleNext}
						disabled={pagina >= totalPaginas - 1}
						style={{
							backgroundColor: "transparent",
							border: "none",
							cursor: pagina >= totalPaginas - 1 ? "not-allowed" : "pointer",
						}}
					>
						<RightOutlined style={{ fontSize: "20px", color: pagina >= totalPaginas - 1 ? "#ccc" : "#333" }} />
					</button>
				</div>
			</Card>
		</div>
	);
}

const Comunicaciones = () => {
	const { publicaciones, totalPublicaciones, loadMorePublicaciones, handleHeartClick, handleLikeClick } =
		usePublicacion();

	return (
		<div
			style={{
				borderRadius: "8px",
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
				backgroundColor: "#fff",
				//padding: "16px",
				margin: "2px auto",
				maxWidth: "860px", // Máximo ancho
				width: "98%", // Ajuste dinámico según pantalla
			}}
		>
			<div
				style={{
					// borderBottom: "1px solid #f0f0f0",
					// paddingBottom: "8px",
					// 			marginBottom: "16px",
					display: "flex",
					alignItems: "center",
					borderBottom: "1px solid #ddd",
				}}
			>
				{/* Título del card */}
				{/* <div
					style={{
						display: "inline-block",
						flex: "1 1",
						padding: "16px 24px",
						overflow: "hidden",
						whiteSpace: "nowrap",
						textOverflow: "ellipsis",
						color: "rgba(0, 0, 0, 0.85)",
						fontWeight: "500",
						fontSize: "16px",
						background: "transparent",
						borderBottom: "1px solid #ddd",
					}}
				> */}
					{/* <h3 style={{ margin: 0, fontSize: "18px", fontWeight: "bold", color: "#333" }}> */}
					{/* Comunicaciones */}
					{/* </h3> */}
				{/* </div> */}
			</div>

			{/* Contenido del card */}
			<div
				style={{
					height: "calc(100% - 60px)",
					paddingTop: "0px",
					paddingBottom: "20px",
					background: "#f5f5f5",
					
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						padding: "0 0",
						// marginTop: "5px",
					}}
				>
					<div
						style={{
							maxWidth: "90vw",
							width: "100%",
						}}
					>
						<InfiniteScroll
							dataLength={publicaciones.length}
							next={loadMorePublicaciones}
							hasMore={publicaciones.length < totalPublicaciones}
							loader={<h4>Loading...</h4>}
						>
							<List
								itemLayout="vertical"
								size="small"
								dataSource={publicaciones}
								renderItem={(item) => (
									<PublicacionItem
										item={item}
										handleHeartClick={handleHeartClick}
										handleLikeClick={handleLikeClick}
									/>
								)}
							/>
						</InfiniteScroll>
					</div>
				</div>
			</div>
		</div>
	);
};

const PublicacionItem = ({ item, handleHeartClick, handleLikeClick }) => {
	return (
		<List.Item
			key={item.id}
			actions={[
				<Space>
					<Button
						type="text"
						icon={
							<HeartOutlined
								style={{
									color: item.heartedColaborador ? "red" : undefined,
									marginRight: 4,
								}}
							/>
						}
						onClick={() => handleHeartClick(item.id)}
					>
						{item.hearts}
					</Button>
					<Button
						type="text"
						icon={
							<LikeOutlined
								style={{
									color: item.likedColaborador ? "blue" : undefined,
									marginRight: 4,
								}}
							/>
						}
						onClick={() => handleLikeClick(item.id)}
					>
						{item.likes}
					</Button>
				</Space>,
			]}
			style={{
				marginBottom: "16px",
				borderRadius: "8px",
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
				overflow: "hidden",
				border: "1px solid #f0f0f0",
				background: "white",
			}}
		>
			<PublicacionContenido item={item} />
		</List.Item>
	);
};

const PublicacionContenido = ({ item }) => {
	return (
		<div>
			{/* Encabezado */}
			<div style={{ display: "flex", alignItems: "flex-start" }}>
				<div
					style={{
						height: "32px",
						overflow: "hidden",
						marginRight: "16px",
					}}
				>
					<img
						src={LoginService.getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo}
						alt="Logotipo de la empresa"
						style={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
						}}
					/>
				</div>
				<div style={{ flex: 1 }}>
					{/* <h4 style={{ margin: 0 }}>{item.razonSocial}</h4>
					<p style={{ margin: 0, color: "rgba(0, 0, 0, 0.45)" }}>
						{`${moment(item.usuarioRegistro.fechaRegistro)
							.locale("es")
							.format("D [de] MMMM [de] YYYY, [a las] hh:mm A")}`}
					</p> */}
				</div>
				<div>
					<Tag
						color={item.colorEtiqueta}
						style={{
							alignSelf: "center",
							marginRight: "16px",
							borderRadius: "12px",
						}}
					>
						{item.etiqueta}
					</Tag>
				</div>
			</div>

			<div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
  <h4 style={{ margin: 0 }}>{item.razonSocial}</h4>
  <p style={{ margin: 0, color: "rgba(0, 0, 0, 0.45)" }}>
    {`${moment(item.usuarioRegistro.fechaRegistro)
      .locale("es")
      .format("D [de] MMMM [de] YYYY, [a las] hh:mm A")}`}
  </p>
</div>
	
			{/* Mensaje */}
			<div>
				<style>
					{`
            .custom-react-quill .ql-container {
              border: none !important; /* Quitar el borde */
              background-color: transparent; /* Fondo transparente */
            }
            `}
				</style>
				<ReactQuill
					value={item.mensajeHTML}
					readOnly={true}
					className="custom-react-quill"
					style={{
						backgroundColor: "transparent",
					}}
					theme="snow"
					modules={{ toolbar: false }}
					placeholder="Escribe algo..."
				/>
			</div>

			{/* Imágenes */}
			<div style={{ width: "100%", marginTop: "16px" }}>
				{item.imagenes && item.imagenes.length > 0 ? (
					item.imagenes.length > 1 ? (
						<Carousel effect="fade" autoplay dotPosition="bottom">
							{item.imagenes.map((imagen, index) => (
								<div key={index} style={{ textAlign: "center" }}>
									<img
										width="100%"
										height="auto"
										alt={`imagen-${index}`}
										src={imagen}
										style={{
											display: "block",
											maxWidth: "100%",
											maxHeight: "400px",
											objectFit: "contain",
										}}
									/>
								</div>
							))}
						</Carousel>
					) : (
						<img
							width="100%"
							height="auto"
							alt="imagen"
							src={item.imagenes[0]}
							style={{
								display: "block",
								maxWidth: "100%",
								maxHeight: "400px",
								objectFit: "contain",
							}}
						/>
					)
				) : null}
			</div>
		</div>
	);
};
export default MiPortalMovil;

const Container = styled.div`
	width: 100%;
`;
const SeccionPortal = styled.div`
	width: 100%;
	display: grid;
	gap: 5px;
	padding: 15px;
	background: #f5f5f5;

	@media (max-width: 860px) {
		/* Styles for smaller screens */
		grid-template-columns: repeat(1, 1fr); /* Stack cards vertically */
	}
`;
