import React, { useState, useEffect } from "react";
import { Card, Avatar, Button, Image, Tooltip } from "antd";
import { RightOutlined, LeftOutlined, UserOutlined, SearchOutlined, SelectOutlined } from "@ant-design/icons";

import { FcPlanner } from "react-icons/fc";
import moment from "moment";
import { PublicacionService } from "services/comunicacion-cultura";
import { useHistory } from "react-router-dom";

const ListaCumpleaniosHoy = () => {
	moment.locale("es");
	const history = useHistory();
	
	const [cumpleanios, setCumpleanios] = useState([]);
	const [pagina, setPagina] = useState(0);
	const [totalPaginas, setTotalPaginas] = useState(0);

	useEffect(() => {
		const getCumpleanios = async () => {
			const respuesta = await PublicacionService.listarCumpleaniosPaginadoHoy({
				pagina: pagina,
				cantidad: 3,
			});
			console.log("Cumpleanios::", respuesta);
			setCumpleanios(respuesta.cumpleanios);
			setTotalPaginas(respuesta.totalPaginas); // Total de páginas desde el backend
		};

		getCumpleanios();
	}, [pagina]);

	const handleNext = () => {
		if (pagina < totalPaginas - 1) {
			setPagina((prev) => prev + 1);
		}
	};

	const handlePrev = () => {
		if (pagina > 0) {
			setPagina((prev) => prev - 1);
		}
	};

	const BirthdayCard = ({ id, nombreYApellidoPaterno, fechaNacimiento, urlFotoPersonal }) => (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			{/* Imagen de perfil con fondo de color */}
			<Tooltip title={nombreYApellidoPaterno} key={id}>
				{/* <div style={{ backgroundColor: "rgb(144 137 137)", borderRadius: "50%", padding: "1px" }}> */}
					{urlFotoPersonal !== null ? (
						// <Avatar
						// 	src={urlFotoPersonal}
						// 	style={{
						// 		width: "70px",
						// 		height: "85px",
						// 		borderRadius: "50%",
						// 	}}
					// />
					<Image src={urlFotoPersonal} width={70} height={70} style={{ borderRadius: "50%" }} preview={false}/>
					) : (
						<Avatar size={70} icon={<UserOutlined />} />
					)}
				{/* </div> */}
			</Tooltip>
		</div>
	);

	const onClickVerMas = () => {
		history.push("/comunicaciones-cultura/cumpleanios");
	};
	return (
		<div style={{ padding: "0px" }}>
			<Card
				title={
					<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								color: "black",
								fontWeight: "normal",
								fontSize: "14px",
							}}
						>
							<FcPlanner size="1.5em" style={{ marginRight: "0.5em" }} />
							<span>Cumpleaños Hoy</span>
						</div>
					</div>
				}
				extra={
					<Button type="link" icon={<SelectOutlined />} onClick={onClickVerMas}>
									Más
					</Button>
				}
				style={{
					marginBottom: "16px",
					borderRadius: "8px",
					boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
					border: "1px solid #f0f0f0",
				}}

				
			>
				{/* Contenido principal */}
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "repeat(3, 1fr)",
						gap: "10px",
						width: "100%",
					}}
				>
					{cumpleanios.map((item, idx) => (
						<BirthdayCard key={idx} {...item} />
					))}
				</div>

				{/* Footer con flechas y mes actual */}
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						padding: "10px 20px",
						marginTop: "10px",
					}}
				>
					<button
						onClick={handlePrev}
						disabled={pagina === 0}
						style={{
							backgroundColor: "transparent",
							border: "none",
							cursor: pagina === 0 ? "not-allowed" : "pointer",
						}}
					>
						<LeftOutlined style={{ fontSize: "20px", color: pagina === 0 ? "#ccc" : "#333" }} />
					</button>

					<span style={{ fontWeight: "normal", fontSize: "14px" }}>
						{moment().format("D [de] MMMM YYYY")}
					</span>

					<button
						onClick={handleNext}
						disabled={pagina >= totalPaginas - 1}
						style={{
							backgroundColor: "transparent",
							border: "none",
							cursor: pagina >= totalPaginas - 1 ? "not-allowed" : "pointer",
						}}
					>
						<RightOutlined style={{ fontSize: "20px", color: pagina >= totalPaginas - 1 ? "#ccc" : "#333" }} />
					</button>
				</div>
			</Card>
		</div>
	);
};

export default ListaCumpleaniosHoy;
