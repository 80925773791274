import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { cumpleaniosAtom, paginaAtom, totalCumpleaniosAtom } from "../store/CumpleaniosStore";
import { PublicacionService } from "services/comunicacion-cultura";
import LoginService from "services/LoginService";

const useCumpleanios = () => {
    const [cumpleanios, setCumpleanios] = useAtom(cumpleaniosAtom);
    const [totalCumpleanios, setTotalCumpleanios] = useAtom(totalCumpleaniosAtom);
    const [pagina, setPagina] = useAtom(paginaAtom);


    useEffect(() => {
		const cargarinformacion = async () => {
            fetchCumpleanios(0);
		};

		cargarinformacion();
    }, []);
    
    const loadMoreCumpleanios = async () => {
        console.log("loadMoreCumpleanios::");
        fetchCumpleanios(pagina + 1);
    }

    const fetchCumpleanios = async (pagina) => {
        const response = await PublicacionService.listarCumpleaniosPaginado({
            pagina: pagina,
            cantidad: 10
        });
        if (pagina === 0) {
            setCumpleanios(response.cumpleanios);
        } else {
            setCumpleanios([...cumpleanios, ...response.cumpleanios]);
        }
        setTotalCumpleanios(response.totalCumpleanios);
        setPagina(pagina);

        console.log("cumpleanios::", response);
        
    } 
    
    return {
        pagina,
        cumpleanios,
        totalCumpleanios,
        loadMoreCumpleanios,
        setPagina
    };
};

export default useCumpleanios;
