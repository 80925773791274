import React from "react";
import { List, Tag, Button, Space } from "antd";
import { HeartOutlined, LikeOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/es";
import { PublicacionContenido } from "../PublicacionContenido";
// import { usePublicacion } from '../../../../hooks';

export const PublicacionItem = ({ item, handleHeartClick, handleLikeClick }) => {  
  return (
    <List.Item
      key={item.id}
      actions={[
        <Space>
          <Button
            type="text"
            icon={
              <HeartOutlined
                style={{
                  color: item.heartedColaborador ? "red" : undefined,
                  marginRight: 4,
                }}
              />
            }
            onClick={() => handleHeartClick(item.id)}
          >
            {item.hearts}
          </Button>
          <Button
            type="text"
            icon={
              <LikeOutlined
                style={{
                  color: item.likedColaborador ? "blue" : undefined,
                  marginRight: 4,
                }}
              />
            }
            onClick={() => handleLikeClick(item.id)}
          >
            {item.likes}
          </Button>
        </Space>,
      ]}
      style={{
        marginBottom: "16px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        border: "1px solid #f0f0f0",
        background: "white",
      }}
    >
      <PublicacionContenido item={item} />
    </List.Item>
  );
};
export default PublicacionItem;