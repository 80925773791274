import React, { useRef, useContext, useState, useEffect, useCallback } from "react";
import { Alert, Input, Button, Modal, Form, notification, Row, Col, Space } from "antd";
import { MisDocumentosContext } from "../../../../../context/v3/MisDocumentosContext";
import { SecurityContext } from "../../../../../context/SecurityContextProvider";
import { Plataforma } from "enum/Plataforma";
import { CategoriaDocumento } from "enum/CategoriaDocumento";
import { visibleVisorFormularioAtom } from "../MisDocumentosWeb/MisDocumentosWebStorage";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";
import SignaturePad from "react-signature-pad-wrapper";
import { PortalColaboradorService } from "services/PortalColaboradorService";
import { useMediaQuery } from "react-responsive";
import FormItem from "antd/lib/form/FormItem";
import ActualizarClaveService from "../../../../../services/ActualizarClaveService";
import LoginService from "../../../../../services/LoginService";
import { CheckOutlined } from "@ant-design/icons";

export function ModalFirmar({ plataforma = Plataforma.WEB }) {
	let history = useHistory();
	const [, setVisibleVisorFormulario] = useAtom(visibleVisorFormularioAtom);
	const [claveIngresada, setClaveIngresada] = useState("");
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const {
		loading,
		mensajeError,
		setMensajeError,
		visibleModalFirmar,
		firmarFormulario,
		firmarDocumento,
		firmarFlujoFirma,
		documentoSeleccionado,
		datosFormulario,
		cerrarModalFirmar,
		setReloadPdf,
		cerrarVisorPdf,
	} = useContext(MisDocumentosContext);

	const [refSignature, setRefSignature] = useState(undefined);

	const isAceros = getUsuarioAutenticado().empresaSeleccionada.razonSocial.toUpperCase().includes("ACEROS CHILCA SAC")
		? true
		: false;

	const [tieneFirma, setTieneFirma] = useState(false);

	const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });

	const signatureCallBackRef = useCallback((signature) => {
		setRefSignature(signature);
	}, []);

	const [visibleCrearClaveModal, setVisibleCrearClaveModal] = useState(false);
	const [formularioCambiarClave] = Form.useForm();
	const [loadingModalClave, setLoadingModalClave] = useState(false);
	const [existeClave, setExisteClave] = useState(false);

	const ismaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const ismaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;

	useEffect(() => {
		const cargarFirma = async () => {
			if (refSignature && documentoSeleccionado?.requiereFirmaHolografaColaborador) {
				const info = await PortalColaboradorService.obtenerUrlFirmaHolografa();
				if (info.urlFirmaHolografa) {
					setTieneFirma(true);
				}
			}
		};
		cargarFirma();
	}, [refSignature]);

	useEffect(() => {
		const cargarValidacion = async () => {
			if (ismaccaferriDePeru || ismaccaferriConstruction) {
				const datos = await PortalColaboradorService.validarExisteClave();
				setExisteClave(datos.existeClave);
			}
		};
		cargarValidacion();
	}, [existeClave]);

	function onClickBtnCancelar() {
		setMensajeError(undefined);
		setClaveIngresada("");
		cerrarModalFirmar();
		if (documentoSeleccionado.mostrarVentanaFirmaAntesDeVisualizarDocumento) {
			cerrarVisorPdf();
		}
	}

	const onClickBtnFirmar = async () => {
		if (
			documentoSeleccionado.categoriaDocumentoId === CategoriaDocumento.FORMULARIO ||
			documentoSeleccionado.categoriaDocumentoId === CategoriaDocumento.FORMATO_AUSENCIA
		) {
			const formulario = {
				detalleEnvioId: documentoSeleccionado.id,
				datos: datosFormulario,
				clave: claveIngresada,
				plataforma,
			};

			const firmaExitosa = await firmarFormulario(formulario);

			if (firmaExitosa) {
				setMensajeError(undefined);
				setClaveIngresada("");
				setVisibleVisorFormulario(false);
				if (plataforma.includes("MOVIL_")) {
					history.push(`/v3/mis-pendientes/visor-pdf/${documentoSeleccionado.id}/true`);
				}
			}
		} else if (documentoSeleccionado.categoriaDocumentoId === CategoriaDocumento.FLUJO_FIRMA) {
			const flujoFirma = {
				detalleEnvioId: documentoSeleccionado.id,
				datos: datosFormulario,
				clave: claveIngresada,
				plataforma,
			};

			const firmaExitosa = await firmarFlujoFirma(flujoFirma);

			if (firmaExitosa) {
				setMensajeError(undefined);
				setClaveIngresada("");
				setVisibleVisorFormulario(false);
				if (plataforma.includes("MOVIL_")) {
					history.push(`/v3/mis-pendientes/true`);
				}
			}
		} else {
			if (documentoSeleccionado?.requiereFirmaHolografaColaborador && !tieneFirma && refSignature.isEmpty()) {
				setMensajeError("Dibuje su firma hológrafa");
				return;
			}

			if (claveIngresada === "") {
				setMensajeError("Ingresa tu clave");
				return;
			}

			const base64ImagenFirmaColaborador =
				documentoSeleccionado.requiereFirmaHolografaColaborador && !tieneFirma
					? refSignature.toDataURL()
					: null;

			const firmaExitosa = await firmarDocumento(
				getUsuarioAutenticado().empresaSeleccionada.id,
				documentoSeleccionado.id,
				claveIngresada,
				plataforma,
				base64ImagenFirmaColaborador
			);
			if (firmaExitosa === true) {
				setMensajeError(undefined);
				setClaveIngresada("");
				setReloadPdf(true);
			}
		}
	};

	const onClickBtnLimpiarFirma = () => {
		refSignature.clear();
	};

	// Funciones para abrir y cerrar el modal para crear clave
	const abrirCrearClaveModal = () => setVisibleCrearClaveModal(true);
	const cerrarCrearClaveModal = () => setVisibleCrearClaveModal(false);

	async function handleOnFinish(formulario) {
		console.log("formularioooo:::", formulario);
		try {
			setLoadingModalClave(true);
			const mensajeValidacion = await ActualizarClaveService.crearClave(
				LoginService.getUsuarioAutenticado().subdominio,
				getUsuarioAutenticado().numeroDocumentoIdentidad,
				formulario.clave
			);
			console.log("mensajeValidacion::", mensajeValidacion);
			let mensaje = (mensajeValidacion != null && mensajeValidacion != "") ? mensajeValidacion.message : null;
			//setMensajeError(mensaje);
			setLoadingModalClave(false);
			if (mensaje == null || mensaje == "") {
				setExisteClave(true);
				notification.success({
					message: "Operación exitosa",
					description: "Se actualizo su clave.",
				});
				setVisibleCrearClaveModal(false)
			}

		} catch (error) {
			console.log(error);
			setLoadingModalClave(false);
			notification.error({
				message: "No se puede guardar la clave",
				description: "Error al generar la clave",
			});
		}
	}

	return (
		<>
			<Modal
				title="Firmar Documento"
				visible={visibleModalFirmar}
				closable={false}
				width={400}
				destroyOnClose={true}
				footer={[
					<Button key="cancelar" disabled={loading} onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
						Cancelar
					</Button>,
					<Button
						key="firmar"
						type="primary"
						loading={loading}
						onClick={onClickBtnFirmar}
						style={{ padding: "0px 30px" }}
					>
						Firmar
					</Button>,
				]}
				centered
			>
				{mensajeError && (
					<Alert
						message={mensajeError}
						onClose={() => setMensajeError(undefined)}
						style={{ marginBottom: "4px" }}
						type="error"
						showIcon
						closable
					/>
				)}

				<span style={{ display: "block", marginBottom: "4px" }}>Ingresa tu clave</span>
				<Input.Password
					value={claveIngresada}
					onChange={(e) => {
						setClaveIngresada(e.target.value);
						setMensajeError(undefined);
					}}
					placeholder={"Clave"}
				/>

				{documentoSeleccionado?.requiereFirmaHolografaColaborador && !tieneFirma && (
					<div style={{ paddingTop: "10px" }}>
						<div>Dibuja tu firma</div>
						<SignaturePad
							ref={signatureCallBackRef}
							// width={esDesktop ? 340 : 170}
							// height={esDesktop ? 150 : 80}
							canvasProps={{
								style: { border: "1px dashed #ccc" },
							}}
							options={{
								minWidth: 2,
								maxWidth: 3,
								penColor: "#000",
							}}
							redrawOnResize={true}
						/>
						<div style={{ textAlign: "right" }}>
							<Button type="link" onClick={onClickBtnLimpiarFirma} style={{ padding: "0" }}>
								Limpiar Firma
							</Button>
						</div>
					</div>
				)}
				{(ismaccaferriDePeru || ismaccaferriConstruction) && !existeClave && (
					<div style={{ paddingTop: "10px" }}>
						<Button type="link" onClick={abrirCrearClaveModal} style={{ padding: "0" }}>
							Crea tu clave para firmar tus documentos.
						</Button>
					</div>
				)}
			</Modal>

			<Modal
				title="Crear Clave"
				open={visibleCrearClaveModal}
				onCancel={cerrarCrearClaveModal}
				footer={null}
			>
				<Form
					form={formularioCambiarClave}
					labelCol={{
						span: 12,
					}}
					wrapperCol={{
						span: 12,
					}}
					layout="vertical"
					size="default"
					onFinish={handleOnFinish}
				>
					<FormItem
						label="Clave"
						name="clave"
						rules={[
							{
								required: true,
								message: "Ingrese su clave",
							},
						]}
					>
						<Input.Password />
					</FormItem>
					<FormItem
						label="Repetir Clave"
						name="claveR"
						dependencies={["clave"]}
						hasFeedback
						rules={[
							{
								required: true,
								message: "Ingrese nuevamente su clave",
							},
							({ getFieldValue }) => ({
								validator(rule, value) {
									if (
										!value ||
										getFieldValue("clave") === value
									) {
										return Promise.resolve();
									}

									return Promise.reject(
										"Las claves deben ser iguales!"
									);
								},
							}),
						]}
					>
						<Input.Password />
					</FormItem>
					<Row justify="center">
						<Space>
							<Col span={20}>
								<Button
									type="primary"
									icon={<CheckOutlined />}
									htmlType="submit"
									loading={loadingModalClave}
								>
									Guardar
								</Button>
							</Col>
							<Col>
								<Button
									type="default"
									onClick={cerrarCrearClaveModal}
								>
									Cancelar
								</Button>
							</Col>
						</Space>
					</Row>
				</Form>
			</Modal>
		</>
	);
}
