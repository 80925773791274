import { useAtom } from "jotai";
import { message } from 'antd';
import { colaboradoresAtom, formInstanceAtom, openModalSubirDocumentoAtom, tiposDocumentoAtom, loadingCargaAtom } from "../ConsultaDocumentoStore";
import TipoDocumentoService from "services/TipoDocumentoService";
import { ColaboradorService } from "services";
import { LegajoColaboradorService } from 'services';
import LoginService from 'services/LoginService';
import useDocumentos from "./useDocumentos";

const useModalSubirDocumento = () => {
    const [open, setOpen] = useAtom(openModalSubirDocumentoAtom);
    const [formInstance, setFormInstance] = useAtom(formInstanceAtom);
    const [tiposDocumento, setTiposDocumento] = useAtom(tiposDocumentoAtom);
    const [colaboradores, setColaboradores] = useAtom(colaboradoresAtom);
    const [loadingCarga, setLoadingCarga] = useAtom(loadingCargaAtom);
    const { cargarDocumentos } = useDocumentos();

    const abrirModal = () => {
        if (formInstance) {
            formInstance.resetFields();
        }
        setOpen(true);
    }

    const cerrarModal = () => {
        setFormInstance(undefined);
        setOpen(false);
    }

    const fetchTiposDocumento = async () => {
        const tiposDocumento = await TipoDocumentoService.listarDocumentosParaSubirPorColaborador();
        setTiposDocumento(
            tiposDocumento.map((tipoDocumento) => ({
                value: tipoDocumento.id,
                label: tipoDocumento.nombre,
                requierefirmaColaboradorPortalSubirDocu: tipoDocumento.requierefirmaColaboradorPortalSubirDocu
            }))
        );
    }

    const onSearchColaboradores = async (value) => {
        if (value === "") {
            setColaboradores([]);
            return;
        }
        const respuesta = await ColaboradorService.listarPorFiltros({
            filtro: value.trim(),
        });

        setColaboradores(
            respuesta.colaboradores.map((colaborador) => ({
                value: colaborador.numeroDocumentoIdentidad,
                label: colaborador.nombreCompleto
            })));
    }

    const onClickBtnSubirDocumento = () => {
        formInstance.validateFields()
            .then(async (datosFormulario) => {
                try {
                    console.log("datosFormulario==>", datosFormulario);
                    setLoadingCarga(true);
                    const formData = new FormData();
                    formData.append("numeroDocumentoIdentidad", datosFormulario.colaboradorId);
                    formData.append("numeroDocumentoIdentidadUsuario", LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad);
                    formData.append("tipoDocumentoId", datosFormulario.tipoDocumentoId);
                    formData.append("periodo", datosFormulario.periodo.format("MM/YYYY"));
                    formData.append("requiereFirmaColaborador", datosFormulario.requiereFirmaColaborador);
                    formData.append("documento", datosFormulario.documento?.file);
                    await LegajoColaboradorService.subirDocumento(formData);

                    message.success("Se subió correctamente el documento");
                    formInstance.resetFields();
                    cargarDocumentos();
                    setLoadingCarga(false);
                    cerrarModal();
                } catch (error) {
                    console.log(error);
                }
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    }

    return {
        open,
        tiposDocumento,
        colaboradores,
        formInstance,
        setFormInstance,
        onClickBtnSubirDocumento,
        onSearchColaboradores,
        fetchTiposDocumento,
        abrirModal,
        cerrarModal,
        loadingCarga
    };
};
export default useModalSubirDocumento;