import React, { useEffect } from "react";
import { Button, Modal } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { styleIcono, styleTitulo, styleMensaje, styleBoton, bodyStyle } from "./estilos";
import { useContext } from "react";
import { MisDocumentosContext } from "../../../../../context/v3/MisDocumentosContext";
import { useParams } from "react-router-dom";

export function ModalExito() {
	const { visibleModalExito, cerrarModalExito, mostrarModalExito } = useContext(MisDocumentosContext);
	const { visibleModalExitoParam } = useParams();

	useEffect(() => {
		if (visibleModalExitoParam === "true") {
			mostrarModalExito();
		}
	}, []);

	return (
		<Modal
			title="Mi Legajo Digital"
			open={visibleModalExito}
			width={400}
			footer={null}
			bodyStyle={bodyStyle}
			onCancel={cerrarModalExito}
			centered
		>
			<CheckCircleOutlined style={styleIcono} />
			<span style={styleTitulo}>Firma Exitosa</span>
			<span style={styleMensaje}>Documento firmado exitosamente</span>
			<Button style={styleBoton} onClick={cerrarModalExito}>
				Aceptar
			</Button>
		</Modal>
	);
}
