import React, { useContext, useState } from "react";
import { Menu} from "antd";
import {
	FileOutlined,
	ContainerOutlined,
	CalendarOutlined,
	FileDoneOutlined,
	ProfileOutlined,
	FormOutlined,
	FileProtectOutlined,
	FileTextOutlined,
	EditOutlined,
	FolderOpenOutlined,
	FileSearchOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { SecurityContext } from "../../../../context/SecurityContextProvider";
import { VersionApiColaborador } from "../../../../enum/VersionApiColaborador";
import {
	FcAlarmClock, FcConferenceCall, FcFinePrint, FcHighPriority, FcTodoList,
	FcPackage, FcSpeaker, FcReading, FcSurvey,FcMultipleSmartphones 
 } from "react-icons/fc";
import LoginService from "services/LoginService";
import styled from '@emotion/styled';
const { SubMenu } = Menu;

export function MenuPrincipalV2({ opciones }) {
	const page = window.location.pathname.split("/")[1];
	const { getVersionApiColaborador, getUsuarioAutenticado } = useContext(SecurityContext);
	const [menuActivo, setMenuActivo] = useState(page === "mis-documentos" ? "bandejaEntrada" : page);
	const isAceros = LoginService.getUsuarioAutenticado()
		.empresaSeleccionada.razonSocial.toUpperCase()
		.includes("ACEROS CHILCA SAC")
		? true
		: false;

	const tieneTareasPendientes = LoginService.getUsuarioAutenticado().empresaSeleccionada.colaborador.tareasPendientes;

	let history = useHistory();

	function handleOnClick(e) {
		switch (e.key) {
			case "portal-colaborador.mis-documentos":
				history.push("/mis-documentos");
				break;

			case "portal-colaborador.mis-formularios":
				history.push("/mis-formularios");
				break;

			case "portal-colaborador.v3.mis-pendientes":
				history.push("/v3/mis-pendientes");
				break;

			case "portal-colaborador.v3.mis-documentos":
				history.push("/v3/mis-documentos");
				break;

			case "bandejaEntrada":
				setMenuActivo(e.key);
				if (getVersionApiColaborador() === VersionApiColaborador.v1) {
					history.push("/bandejaEntrada");
					break;
				} else {
					history.push("/mis-documentos");
					break;
				}

			case "bandejaConsulta":
				setMenuActivo(e.key);
				history.push("/bandejaConsulta");
				break;

			case "portal-colaborador.vacaciones":
				setMenuActivo(e.key);
				history.push("/vacaciones/solicitudes");
				break;

			case "bandejaEvaluacion":
				setMenuActivo(e.key);
				history.push("/bandejaEvaluacion");
				break;

			case "portal-colaborador.solicitud-personal":
				setMenuActivo(e.key);
				history.push("/solicitud-personal");
				break;

			case "portal-colaborador.entrevistas":
				setMenuActivo(e.key);
				history.push("/entrevistas");
				break;

			case "portal-colaborador.tareas-pendientes":
				setMenuActivo(e.key);
				history.push("/tareas-pendientes");
				break;

			case "portal-colaborador.renovacion-contratos":
				setMenuActivo(e.key);
				history.push("/contratos/renovacion");
				break;

			case "portal-colaborador.solicitud-documentos":
				setMenuActivo(e.key);
				history.push("/solicitud-documentos");
				break;

			case "portal-colaborador.consulta-documentos":
				setMenuActivo(e.key);
				history.push("/consulta-documentos");
				break;

			case "portal-colaborador.v3.mis-comunicaciones":
				setMenuActivo(e.key);
				history.push("/v3/mi-portal");
				break;
			
			default:
				console.error("No existe ningún item con el key: ", e.key);
		}
	}

	function tieneAcceso(codigo) {
		return opciones?.includes(codigo);
	}

	function mostrarMenuItem(codigo, nombre, icono) {
		if (tieneAcceso(codigo)) {
			return (
				// <Menu.Item key={codigo} icon={icono} style={{ color: "black" , fontWeight:"normal", fontSize:"14px"}}>
				// 	{nombre}
				// </Menu.Item>
				<StyledMenuItem key={codigo} icon={icono}>
				{nombre}
				</StyledMenuItem>
			);
		}
		return null;
	}

	// function mostrarMenuItem2(codigo, nombre, icono) {
	// 	// if (tieneAcceso(codigo)) {
	// 		return (
	// 			// <Menu.Item key={codigo} icon={icono} style={{ color: "black" , fontWeight:"normal", fontSize:"14px"}}>
	// 			// 	{nombre}
	// 			// </Menu.Item>
	// 			<StyledMenuItem key={codigo} icon={icono}>
	// 			{nombre}
	// 			</StyledMenuItem>
	// 		);
	// 	// }
	// 	// return null;
	// }
	function mostrarMenuConHijos(codigo, nombre, icono, hijos) {
    return (
		<SubMenu key={codigo} icon={icono} title={nombre} style={{ color:"black", fontWeight: "normal",
  			fontSize: "14px", transition: "color 0.3s"}}>
        {hijos.map((hijo) => (
          <StyledMenuItem
            key={hijo.key}
            onClick={() => window.open(hijo.url, "_blank")} // Abrir en nueva pestaña
          >
            {hijo.nombre}
          </StyledMenuItem>
        ))}
      </SubMenu>
    );
  }
	return (
		<Menu mode="inline" onClick={handleOnClick} defaultSelectedKeys={menuActivo}>
			{mostrarMenuItem(
				"portal-colaborador.v3.mis-comunicaciones",
				"Mi Muro",
				<FcMultipleSmartphones size="1.5em" />
			)}
			{isAceros && getUsuarioAutenticado().versionPortalColaborador == "v2" &&
				mostrarMenuConHijos(
					"portal-colaborador.beneficiosAceros",
					"Mis beneficios",
					<FcReading size="1.5em" />,
					[
						{
							key: "beneficio1",
							nombre: "BeeBeneficios",
							url: "https://beebeneficios.com.pe/hola/login",
						},
						{
							key: "beneficio2",
							nombre: "Descuentos Repsol",
							url: "https://repsolperu.everilion.com/ILIONX45/Custom/RepsolPeru/Fidelizacion/LoginProgramaFidelizacion.aspx",
						},
						{
							key: "beneficio3",
							nombre: "Descuentos Primax",
							url: "https://convenios.primax.com.pe",
						},
					]
				)
			}
			{mostrarMenuItem("bandejaEntrada", "Mis Documentos", <FcFinePrint size="1.5em" />)}
			{mostrarMenuItem("portal-colaborador.mis-documentos", "Mis Documentos", <FcFinePrint size="1.5em" />)}
			{mostrarMenuItem(
				"portal-colaborador.v3.mis-pendientes",
				"Documentos Pendientes",
				<FcHighPriority size="1.5em" />
			)}
			{mostrarMenuItem("portal-colaborador.v3.mis-documentos", "Mis Documentos", <FcFinePrint size="1.5em" />)}
			{mostrarMenuItem("bandejaConsulta", "Buzón de Consultas", <ContainerOutlined />)}
			{mostrarMenuItem("bandejaEvaluacion", "Evaluación de Desempeño", <FileDoneOutlined />)}
			{mostrarMenuItem("portal-colaborador.vacaciones", "Ausencias y Vacaciones", <FcAlarmClock size="1.5em" />)}
			{mostrarMenuItem(
				"portal-colaborador.solicitud-personal",
				"Solicitud Personal",
				<FcConferenceCall size="1.5em" />
			)}
			{mostrarMenuItem("portal-colaborador.entrevistas", "Entrevistas", <ProfileOutlined />)}
			{tieneTareasPendientes &&
				mostrarMenuItem(
					"portal-colaborador.tareas-pendientes",
					"Tareas Pendientes",
					<FcTodoList size="1.5em" />
				)}

			{(LoginService.getUsuarioAutenticado().empresaSeleccionada.colaborador.administradorObra ||
				LoginService.getUsuarioAutenticado().empresaSeleccionada.colaborador.jefeProyecto) &&
				mostrarMenuItem(
					"portal-colaborador.renovacion-contratos",
					"Renovación Contratos",
					<FileProtectOutlined />
				)}
			{isAceros &&
				!LoginService.getUsuarioAutenticado()
					.empresaSeleccionada.colaborador.cargoNombre.toUpperCase()
					.includes("PRACTICANTE") &&
				mostrarMenuItem(
					"portal-colaborador.solicitud-documentos",
					"Solicitud Documentos",
					<FcPackage size="1.5em" />
				)}

			{!isAceros &&
				mostrarMenuItem(
					"portal-colaborador.solicitud-documentos",
					"Solicitud Documentos",
					<FcPackage size="1.5em" />
				)}

			{LoginService.getUsuarioAutenticado().empresaSeleccionada.colaborador
				.tieneAccesoOpcionConsultaDocumentoPortalColaborador &&
				mostrarMenuItem(
					"portal-colaborador.consulta-documentos",
					"Consulta Documentos",
					<FileSearchOutlined />
				)}
		</Menu>
	);
}

const StyledMenuItem = styled(Menu.Item)`
  color: black; /* Color por defecto */
  font-weight: normal;
  font-size: 14px;
  transition: color 0.3s;

  &:hover {
    color: #1890ff; /* Azul en hover */
  }
`;