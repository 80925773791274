import React, { useState, useEffect } from "react";
import { Card, Avatar, Button, Image, Tooltip} from "antd";
import {
	RightOutlined, LeftOutlined,
	UserOutlined, TrophyOutlined
} from "@ant-design/icons";
import {
	FcRating
 } from "react-icons/fc";
import moment from "moment";
import { PublicacionService } from "services/comunicacion-cultura";

const ListaReconocimientos = () => {
	moment.locale("es");

	const [reconocimientos, setReconocimientos] = useState([]);
	const [pagina, setPagina] = useState(0);
	const [totalPaginas, setTotalPaginas] = useState(0);

	useEffect(() => {
		const getReconocimientos = async () => {
			const respuesta = await PublicacionService.listarReconocimientosPaginado({
				pagina: pagina,
				cantidad: 2,
      });
      console.log("reconocimientos::", respuesta);
			setReconocimientos(respuesta.reconocimientos);
			setTotalPaginas(respuesta.totalPaginas); // Total de páginas desde el backend
		};

		getReconocimientos();
	}, [pagina]);

	const handleNext = () => {
		if (pagina < totalPaginas - 1) {
			setPagina((prev) => prev + 1);
		}
	};

	const handlePrev = () => {
		if (pagina > 0) {
			setPagina((prev) => prev - 1);
		}
	};

	const ReconocimientoCard = ({ id, colaborador, usuarioRegistro, titulo }) => (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<span style={{ fontSize: "12px", fontWeight: "normal", color: "black" }}>
				{moment(usuarioRegistro.fechaRegistro).format("MMM").toUpperCase()}{moment(usuarioRegistro.fechaRegistro).format("DD")}
			</span>
		
			{/* Imagen de perfil con fondo de color */}
			<Tooltip title={colaborador.nombreCompleto} key={`tooltip-image-${id}`}>
					{colaborador.urlFotoPersonal !== null ? (
					<Image src={colaborador.urlFotoPersonal} width={70} height={70} style={{ borderRadius: "50%" }} preview={false}/>
					) : (
						<Avatar size={80} icon={<UserOutlined />} />
					)}
			</Tooltip>
			{/* Fecha de reconocimiento */}
			{/* <Tooltip title={titulo} key={`tooltip-title-${id}`} color={"#87d068"}>
				<span style={{ marginTop: "5px", fontSize: "12px", fontWeight: "bold", color: "black" }}>
					<TrophyOutlined style={{ color: "#fadb14", marginRight: "5px" }} />
					{titulo.length > 15 ? `${titulo.substring(0, 15)}...` : titulo}
				</span>
			</Tooltip> */}
			<div
  style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Centra los elementos en el eje horizontal
    textAlign: "center", // Centra el texto
  }}
>
  <Tooltip title={titulo} key={`tooltip-title-${id}`} color={"#87d068"}>
    <span
      style={{
        marginTop: "5px",
        fontSize: "12px",
        fontWeight: "bold",
        color: "black",
      }}
    >
      <TrophyOutlined style={{ color: "#fadb14", marginRight: "5px" }} />
      {titulo.length > 15 ? `${titulo.substring(0, 15)}...` : titulo}
    </span>
  </Tooltip>
</div>
		</div>
	);

	return (
		<div style={{ padding: "0px" }}>
			<Card
				title={
					<div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								color: "black",
								fontWeight: "normal",
								fontSize: "14px",
							}}
						>
							<FcRating size="1.5em" style={{ marginRight: "0.5em" }} />
							<span>Reconocimientos</span>
						</div>
					</div>
				}
				// extra={
				// 	<Button type="link" icon={<SelectOutlined />} onClick={onClickVerMas}>
				// 					Más
				// 	</Button>
				// }
				style={{
					marginBottom: "16px",
					borderRadius: "8px",
					boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
					border: "1px solid #f0f0f0",
				}}

				
			>
				{/* Contenido principal */}
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "repeat(2, 1fr)",
						gap: "10px",
						width: "100%",
					}}
				>
					{reconocimientos.map((item, idx) => (
						<ReconocimientoCard key={idx} {...item} />
					))}
				</div>

				{/* Footer con flechas y mes actual */}
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						padding: "10px 20px",
						marginTop: "10px",
					}}
				>
					<button
						onClick={handlePrev}
						disabled={pagina === 0}
						style={{
							backgroundColor: "transparent",
							border: "none",
							cursor: pagina === 0 ? "not-allowed" : "pointer",
						}}
					>
						<LeftOutlined style={{ fontSize: "20px", color: pagina === 0 ? "#ccc" : "#333" }} />
					</button>

					<span style={{ fontWeight: "normal", fontSize: "14px" }}>
						{moment().format("MMMM").charAt(0).toUpperCase() + moment().format("MMMM").slice(1)}
					</span>

					<button
						onClick={handleNext}
						disabled={pagina >= totalPaginas - 1}
						style={{
							backgroundColor: "transparent",
							border: "none",
							cursor: pagina >= totalPaginas - 1 ? "not-allowed" : "pointer",
						}}
					>
						<RightOutlined style={{ fontSize: "20px", color: pagina >= totalPaginas - 1 ? "#ccc" : "#333" }} />
					</button>
				</div>
			</Card>
		</div>
	);
};

export default ListaReconocimientos;
