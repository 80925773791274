import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill editor styles
import {
	Badge,
	Carousel,
	Breadcrumb,
	Input,
	Typography,
	Descriptions,
	Avatar,
	List,
	Skeleton,
	Button,
	Tag,
	Space,
	Empty
} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spin } from "antd";
import LoginService from "services/LoginService";
import { PublicacionItem } from "./componentes/PublicacionItem"; // Componente individual
import { PublicacionService } from "services/comunicacion-cultura";
import { usePublicacion } from '../../hooks';
import {
	UserOutlined, StarOutlined, LikeOutlined, MessageOutlined, HeartOutlined,
	RightOutlined, LeftOutlined
} from "@ant-design/icons";
import { publicacionesAtom, paginaAtom, totalPublicacionesAtom } from "../../store/PublicacionStore";
import { HomeOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/es";
const { Text } = Typography;



const ListaPublicaciones = () => {
	moment.locale("es");
	const { publicaciones, totalPublicaciones, loadMorePublicaciones, handleHeartClick, handleLikeClick } = usePublicacion();
	
	if (publicaciones.length === 0) {
        return (
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No existe datos." />
        )
    }
	
	return (
		<InfiniteScroll
			dataLength={publicaciones.length}
			next={loadMorePublicaciones}
			hasMore={publicaciones.length < totalPublicaciones}
			loader={<h4>Loading...</h4>}
			scrollThreshold={0.9} // Activa la carga cuando se alcanza el 90% del scroll
      		scrollableTarget={null} // Sin contenedor, usa el scroll del navegador
		>
			<List
				itemLayout="vertical"
				size="large"
				dataSource={publicaciones}
				renderItem={(item) =>
					<PublicacionItem
					item={item}
					handleHeartClick={handleHeartClick}
            		handleLikeClick={handleLikeClick}/>}
			/>
		</InfiniteScroll>
	);
};

export default ListaPublicaciones;
