import React, { useEffect, useState } from "react";
import { FastAverageColor } from "fast-average-color";
import { Tag, Spin, Modal } from "antd";
import ReactQuill from "react-quill";
import moment from "moment";
import LoginService from "services/LoginService";

export const PublicacionContenido = ({ item }) => {
  const fac = new FastAverageColor();
  const [bgColor, setBgColor] = useState("#ffffff");
  const [isSmallImage, setIsSmallImage] = useState(false);
  const [compressedImages, setCompressedImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleImage, setVisibleImage] = useState(null); // Imagen seleccionada para ampliar

  // Función para comprimir imágenes
  // const compressImage = (src, maxWidth, maxHeight, quality = 0.8) => {
  //   return new Promise((resolve, reject) => {
  //     const img = new Image();
  //     img.crossOrigin = "Anonymous";
  //     img.src = src;

  //     img.onload = () => {
  //       const canvas = document.createElement("canvas");
  //       let width = img.width;
  //       let height = img.height;

  //       // Ajustar dimensiones manteniendo la proporción
  //       if (width > maxWidth || height > maxHeight) {
  //         if (width / height > maxWidth / maxHeight) {
  //           width = maxWidth;
  //           height = (img.height * maxWidth) / img.width;
  //         } else {
  //           height = maxHeight;
  //           width = (img.width * maxHeight) / img.height;
  //         }
  //       }

  //       canvas.width = width;
  //       canvas.height = height;

  //       const ctx = canvas.getContext("2d");
  //       ctx.drawImage(img, 0, 0, width, height);
  //       const compressedDataUrl = canvas.toDataURL("image/jpeg", quality);

  //       resolve(compressedDataUrl);
  //     };

  //     img.onerror = (error) => reject(error);
  //   });
  // };

  // const compressImage = (src, maxWidth, maxHeight, quality = 0.9) => {
	// 	return new Promise((resolve, reject) => {
	// 		const img = new Image();
	// 		img.crossOrigin = "Anonymous";
	// 		img.src = src;

	// 		img.onload = () => {
	// 			const canvas = document.createElement("canvas");
	// 			const ctx = canvas.getContext("2d");

	// 			// Ajustar dimensiones manteniendo proporción
	// 			let [width, height] = [img.width, img.height];
	// 			if (width > maxWidth || height > maxHeight) {
	// 				const aspectRatio = width / height;
	// 				if (aspectRatio > 1) {
	// 					width = maxWidth;
	// 					height = maxWidth / aspectRatio;
	// 				} else {
	// 					height = maxHeight;
	// 					width = maxHeight * aspectRatio;
	// 				}
	// 			}

	// 			canvas.width = width;
	// 			canvas.height = height;

	// 			// Renderizar en canvas
	// 			ctx.drawImage(img, 0, 0, width, height);

	// 			// Exportar a DataURL con mayor calidad
	// 			resolve(canvas.toDataURL("image/jpeg", quality));
	// 		};

	// 		img.onerror = (err) => reject(err);
	// 	});
  // };

  const compressImage = (src, maxSize = 540, quality = 0.8) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = src;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;

      // Escalar manteniendo la relación de aspecto
      if (width > height) {
        if (width > maxSize) {
          height = (height * maxSize) / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width = (width * maxSize) / height;
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      const compressedDataUrl = canvas.toDataURL("image/jpeg", quality);
      resolve(compressedDataUrl);
    };

    img.onerror = (error) => reject(error);
  });
};

  useEffect(() => {
		const processImages = async () => {
			setLoading(true);

			if (item.imagenes && item.imagenes.length > 0) {
				try {
					// Comprimir todas las imágenes
					const processedImages = await Promise.all(
						item.imagenes.map(
							(src) => compressImage(src, 720, 500, 0.8) // Ajustar tamaño y calidad
						)
					);
					setCompressedImages(processedImages);

					// Determinar si las imágenes necesitan fondo
					const testImage = new Image();
					testImage.crossOrigin = "Anonymous";
					testImage.src = processedImages[0];

					testImage.onload = () => {
						const containerWidth = 720;
						const containerHeight = 500;
						const tolerance = 3;

						// Verificar si la imagen es pequeña
						const isWidthSmall = testImage.width < containerWidth - tolerance;
						const isHeightSmall = testImage.height < containerHeight - tolerance;

						if (isWidthSmall || isHeightSmall) {
							setIsSmallImage(true);

							// Obtener color de fondo para imágenes pequeñas
							const color = fac.getColor(testImage);
							setBgColor(color.rgba);
						} else {
							setIsSmallImage(false);
						}
					};
				} catch (error) {
					console.error("Error al procesar imágenes:", error);
				}
			}

			setLoading(false);
		};

		processImages();
  }, [item.imagenes]);


  // Renderizar imágenes en un diseño de cuadrícula dinámica
  const renderImageGrid = () => {
    const imageCount = compressedImages.length;

    // Estilo base para las imágenes en la cuadrícula
    const baseStyle = {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "4px",
      cursor: "pointer", // Indicador de que es clicable
    };

    if (imageCount === 1) {
      return (
        <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: "8px" }}>
          <img
            src={compressedImages[0]}
            alt="imagen"
            style={baseStyle}
            onClick={() => setVisibleImage(compressedImages[0])} // Abrir modal
          />
        </div>
      );
    } else if (imageCount === 2) {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "8px",
          }}
        >
          {compressedImages.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`imagen-${index}`}
              style={baseStyle}
              onClick={() => setVisibleImage(img)} // Abrir modal
            />
          ))}
        </div>
      );
    } else if (imageCount === 3) {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            gap: "8px",
            gridTemplateRows: "1fr 1fr",
          }}
        >
          <img
            src={compressedImages[0]}
            alt="imagen-1"
            style={{ ...baseStyle, gridRow: "1 / 3" }}
            onClick={() => setVisibleImage(compressedImages[0])} // Abrir modal
          />
          <img
            src={compressedImages[1]}
            alt="imagen-2"
            style={baseStyle}
            onClick={() => setVisibleImage(compressedImages[1])} // Abrir modal
          />
          <img
            src={compressedImages[2]}
            alt="imagen-3"
            style={baseStyle}
            onClick={() => setVisibleImage(compressedImages[2])} // Abrir modal
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "8px",
          }}
        >
          {compressedImages.slice(0, 4).map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`imagen-${index}`}
              style={baseStyle}
              onClick={() => setVisibleImage(img)} // Abrir modal
            />
          ))}
          {imageCount > 4 && (
            <div
              style={{
                position: "relative",
                overflow: "hidden",
              }}
            >
              <img
                src={compressedImages[4]}
                alt="imagen-extra"
                style={baseStyle}
                onClick={() => setVisibleImage(compressedImages[4])} // Abrir modal
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "24px",
                  fontWeight: "bold",
                  cursor: "default",
                }}
              >
                +{imageCount - 4}
              </div>
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div>
      {/* Encabezado */}
			<div style={{ display: "flex", alignItems: "flex-start" }}>
				<div
					style={{
						height: "32px",
						overflow: "hidden",
						marginRight: "16px",
					}}
				>
					<img
						src={LoginService.getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo}
						alt="Logotipo de la empresa"
						style={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
						}}
					/>
				</div>
				<div style={{ flex: 1 }}>
					<h4 style={{ margin: 0 }}>{item.razonSocial}</h4>
					<p style={{ margin: 0, color: "rgba(0, 0, 0, 0.45)" }}>
						{`${moment(item.usuarioRegistro.fechaRegistro)
							.locale("es")
							.format("D [de] MMMM [de] YYYY, [a las] hh:mm A")}`}
					</p>
				</div>
				<div>
					<Tag
						color={item.colorEtiqueta}
						style={{
							alignSelf: "center",
							marginRight: "16px",
							borderRadius: "12px",
						}}
					>
						{item.etiqueta}
					</Tag>
				</div>
			</div>

      {/* Mensaje */}
      <div>
        <style>
					{`
          .custom-react-quill .ql-container {
            border: none !important; /* Quitar el borde */
            background-color: transparent; /* Fondo transparente */
          }
          `}
				</style>
        <ReactQuill
					value={item.mensajeHTML}
					readOnly={true}
					className="custom-react-quill"
					style={{
						backgroundColor: "transparent",
					}}
					theme="snow"
					modules={{ toolbar: false }}
					placeholder="Escribe algo..."
				/>
      </div>

      {/* Imágenes */}
      <div
        style={{
          width: "100%",
          marginTop: "16px",
          backgroundColor: isSmallImage ? bgColor : "transparent",
          padding: isSmallImage ? "16px" : "0px",
          borderRadius: "8px",
        }}
      >
        {loading ? <Spin /> : renderImageGrid()}
      </div>

      {/* Modal para la imagen ampliada */}
      <Modal
        open={!!visibleImage}
        footer={null}
        onCancel={() => setVisibleImage(null)}
        centered
      >
        <img
          src={visibleImage}
          alt="Vista ampliada"
          style={{ width: "100%", height: "auto", borderRadius: "8px" }}
        />
      </Modal>
    </div>
  );
};

export default PublicacionContenido;
