import React, { useEffect, useContext } from "react";
import { MisDocumentosContext } from "../../../../../../../context/v3/MisDocumentosContext";
import { Button, Table } from "antd";
import { FormOutlined, SearchOutlined } from "@ant-design/icons";
import { ColumnaDocumento } from "./componentes/ColumnaDocumento";
import { ColumnaEstadoDocumento } from "./componentes/ColumnaEstadoDocumento";
import { SecurityContext } from "../../../../../../../context/SecurityContextProvider";
import { CategoriaDocumento } from "enum/CategoriaDocumento";
import { EstadoVistaColaborador } from "enum/EstadoVistaColaborador";
import { useAtom } from "jotai";
import { formularioSeleccionadoAtom, visibleVisorFormularioAtom } from "../../MisDocumentosWebStorage";
import { visibleModalAlertaAtom } from "pages/v3/mis-pendientes/storage/MisDocumentosStorage";
import {
	loadingPdfAtom,
} from "pages/v3/mis-pendientes/componentes/MisDocumentosWeb/MisDocumentosWebStorage";

export function ListaDocumentos() {
	const [, setFormularioSeleccionado] = useAtom(formularioSeleccionadoAtom);
	const [, setVisibleVisorFormulario] = useAtom(visibleVisorFormularioAtom);
	const [, setVisibleModalAlerta] = useAtom(visibleModalAlertaAtom);
	const [loadingPdf, setLoadingPdf] = useAtom(loadingPdfAtom);

	const { loading, documentos, getDocumentos, mostrarVisorPdf, setDocumentoSeleccionado, mostrarModalUpload } = useContext(MisDocumentosContext);

	const { getUsuarioAutenticado } = useContext(SecurityContext);

	useEffect(() => {
		getDocumentos(getUsuarioAutenticado().empresaSeleccionada.id, getUsuarioAutenticado().numeroDocumentoIdentidad);
	}, []);

	async function onClickVerDocumento(documento) {
		if (documento.permitirVisualizarDocumento) {
			mostrarVisorPdf(documento.id);
		} else {
			setVisibleModalAlerta(true);
		}
	}

	async function onClickCargar(documento) {
		if (documento.permitirVisualizarDocumento) {
			setDocumentoSeleccionado(documento);
			mostrarModalUpload(documento.id);
		} else {
			setVisibleModalAlerta(true);
		}
	}

	const onClickCompletarFormulario = (formulario) => {
		if (formulario.permitirVisualizarDocumento) {
			setDocumentoSeleccionado(formulario); //uso temporal mientras se use ContextProvider
			setFormularioSeleccionado(formulario);
			setVisibleVisorFormulario(true);
			setLoadingPdf(true);
		} else
			setVisibleModalAlerta(true);

	};

	const columnas = [
		{
			title: "Documento",
			key: "descripcion",
			render: (text, fila) => <ColumnaDocumento fila={fila} />,
		},
		{
			title: "Estado",
			align: "center",
			dataIndex: "estado",
			key: "estado",
			render: (text, fila) => (
				<ColumnaEstadoDocumento
					permitirVisualizarDocumento={fila.permitirVisualizarDocumento}
					estado={fila.estadoVistaColaborador}
					estadoAprobacion={fila.estadoAprobacion}
				/>
			),
		},
		{
			title: "Fecha de envío",
			align: "center",
			dataIndex: "fechaEnvio",
			key: "fechaEnvio",
		},
		{
			title: "Acciones",
			key: "acciones",
			align: "center",
			render: (texto, fila) => {
				if (
					(fila.categoriaDocumentoId === CategoriaDocumento.FORMULARIO || fila.categoriaDocumentoId === CategoriaDocumento.FORMATO_AUSENCIA) &&
					(fila.estadoVistaColaborador !== EstadoVistaColaborador.FIRMADO ||
						(fila.estadoAprobacion != null && fila.estadoAprobacion === "RECHAZADO"))
				) {
					return (
						<Button type="link" onClick={() => onClickCompletarFormulario(fila)}>
							<FormOutlined /> Ver Documento
						</Button>
					);

				} else if (fila.categoriaDocumentoId === CategoriaDocumento.FLUJO_FIRMA) {
					return (
						<Button type="link" onClick={() => onClickCompletarFormulario(fila)}>
							<FormOutlined /> Firmar Documento
						</Button>
					);

				} else if (
					fila.categoriaDocumentoId === CategoriaDocumento.CARGA_DOCUMENTO &&
					(fila.estadoVistaColaborador !== EstadoVistaColaborador.FIRMADO ||
						(fila.estadoAprobacion != null && fila.estadoAprobacion === "RECHAZADO"))
				) {
					return (
						<Button type="link" onClick={() => onClickCargar(fila)}>
							<FormOutlined /> Cargar
						</Button>
					);
				}

				return (
					<Button type="link" onClick={() => onClickVerDocumento(fila)}>
						<SearchOutlined /> Ver Documento
					</Button>
				);
			},
		},
	];

	return (
		<Table
			rowKey={(documentos) => documentos.id}
			dataSource={documentos}
			columns={columnas}
			loading={loading}
			pagination={false}
		/>
	);
}
