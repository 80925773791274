import React, { useReducer } from "react";
import { MisDocumentosContext } from "./MisDocumentosContext";
import { misDocumentosReducer } from "./MisDocumentosReducer";
import { PortalColaboradorService } from "../../services/PortalColaboradorService";
import { SolicitudAusenciaService } from "services";
import { EstadoVistaColaborador } from "../../enum/EstadoVistaColaborador";
import { Plataforma } from "enum/Plataforma";

export function MisDocumentosContextProvider({ children }) {
	const estadoInicial = {
		loading: false,
		loadingPdf: false,
		reloadPdf: undefined,
		mensajeError: undefined,
		visibleVisorPdf: false,
		visibleVisorFormulario: false,
		visibleModalCompletarFormulario: false,
		visibleModalFirmar: false,
		visibleModalExito: false,
		visibleModalConsultar: false,
		documentos: [],
		documentoSeleccionadoId: undefined,
		documentoSeleccionado: undefined,
		datosFormulario: undefined,
		urlPdf: undefined,
		blobPdf: undefined,
		consultas: [],
		visibleModalUpload: false,
	};

	const [state, dispatch] = useReducer(misDocumentosReducer, estadoInicial);

	const getDocumentos = async (empresaId, numeroDocumentoIdentidad) => {
		try {
			dispatch({ type: "INICIAR" });
			const documentos = await PortalColaboradorService.listarDocumentosPendientes(empresaId, numeroDocumentoIdentidad);
			dispatch({ type: "SET_DOCUMENTOS", payload: documentos });
		} catch (error) {
			console.error(error);
		} finally {
			dispatch({ type: "FINALIZAR" });
		}
	};

	const setDocumentoSeleccionado = (documentoSeleccionado) => {
		dispatch({
			type: "SET_DOCUMENTO_SELECCIONADO",
			payload: documentoSeleccionado,
		});
	};

	const obtenerDocumento = async (empresaId, documentoId) => {
		try {
			dispatch({ type: " INICIAR" });
			const documento = await PortalColaboradorService.obtenerDocumento(empresaId, documentoId);
			dispatch({
				type: "SET_DOCUMENTO_SELECCIONADO",
				payload: documento,
			});
		} catch (error) {
			console.error(error);
		} finally {
			dispatch({ type: " FINALIZAR" });
		}
	};

	const getUrlPdf = async (empresaId, documentoId, plataforma = Plataforma.WEB) => {
		try {
			dispatch({ type: "INICIAR" });
			const respuesta = await PortalColaboradorService.obtenerUrl(empresaId, documentoId, plataforma);
			dispatch({ type: "SET_URL_PDF", payload: respuesta.urlDocumento });
			dispatch({
				type: "SET_DOCUMENTO_SELECCIONADO",
				payload: respuesta.documento,
			});
			dispatch({
				type: "SET_DOCUMENTOS",
				payload: state.documentos.map((documento) => {
					if (documento.id === respuesta.documento.id) {
						return respuesta.documento;
					}
					return documento;
				}),
			});

			return respuesta;
		} catch (error) {
			console.error(error);
		} finally {
			dispatch({ type: "FINALIZAR" });
		}
	};

	const firmarDocumento = async (empresaId, documentoId, claveIngresada, plataformaFirma, base64FirmaColaborador) => {
		try {
			dispatch({ type: "INICIAR" });
			await PortalColaboradorService.firmar(
				empresaId,
				documentoId,
				claveIngresada,
				plataformaFirma,
				base64FirmaColaborador
			);
			dispatch({
				type: "SET_DOCUMENTO_SELECCIONADO",
				payload: {
					...state.documentoSeleccionado,
					estadoVistaColaborador: EstadoVistaColaborador.FIRMADO,
				},
			});

			dispatch({ type: "CERRAR_MODAL_FIRMAR" });
			dispatch({ type: "MOSTRAR_MODAL_EXITO" });

			const documentos = await PortalColaboradorService.listarDocumentos(empresaId, "token");
			dispatch({ type: "SET_DOCUMENTOS", payload: documentos });

			return true;
		} catch (error) {
			console.error(error?.response?.data?.mensaje);
			dispatch({
				type: "SET_MENSAJE_ERROR",
				payload: error?.response?.data?.mensaje,
			});
		} finally {
			dispatch({ type: "FINALIZAR" });
		}
	};

	const firmarFormulario = async (formulario) => {
		try {
			dispatch({ type: "INICIAR" });
			await PortalColaboradorService.completarFormulario(formulario);
			dispatch({
				type: "SET_DOCUMENTO_SELECCIONADO",
				payload: {
					...state.documentoSeleccionado,
					estadoVistaColaborador: EstadoVistaColaborador.FIRMADO,
				},
			});

			dispatch({
				type: "SET_DOCUMENTOS",
				payload: state.documentos.map((documento) => {
					if (documento.id === state.documentoSeleccionado.id) {
						return {
							...documento,
							estadoVistaColaborador: EstadoVistaColaborador.FIRMADO,
						};
					}
					return documento;
				}),
			});
			dispatch({ type: "CERRAR_MODAL_COMPLETAR_FORMULARIO" });
			dispatch({ type: "CERRAR_MODAL_FIRMAR" });
			mostrarVisorPdf(state.documentoSeleccionado.id);
			dispatch({ type: "MOSTRAR_MODAL_EXITO" });
			return true;
		} catch (error) {
			console.error(error?.response?.data?.mensaje);
			dispatch({
				type: "SET_MENSAJE_ERROR",
				payload: error?.response?.data?.mensaje,
			});
		} finally {
			dispatch({ type: "FINALIZAR" });
		}
	};

	const firmarFlujoFirma = async (documento) => {
		try {
			dispatch({ type: "INICIAR" });
			const respuesta = await PortalColaboradorService.firmarFlujoFirma(documento);
			dispatch({
				type: "SET_DOCUMENTO_SELECCIONADO",
				payload: {
					...state.documentoSeleccionado,
					estadoVistaColaborador: EstadoVistaColaborador.FIRMADO,
				},
			});
			dispatch({
				type: "SET_DOCUMENTOS",
				payload: state.documentos.filter((documento) => documento.id !== state.documentoSeleccionado.id),
			});
			dispatch({ type: "CERRAR_MODAL_COMPLETAR_FORMULARIO" });
			dispatch({ type: "CERRAR_MODAL_FIRMAR" });
			dispatch({ type: "MOSTRAR_MODAL_EXITO" });
			return true;
		} catch (error) {
			console.error(error?.response?.data?.mensaje);
			dispatch({
				type: "SET_MENSAJE_ERROR",
				payload: error?.response?.data?.mensaje,
			});
		} finally {
			dispatch({ type: "FINALIZAR" });
		}
	};

	//
	const getConsultas = async (empresaId, numeroDocumentoIdentidad) => {
		try {
			dispatch({ type: "INICIAR" });
			const consultas = await PortalColaboradorService.listaConsultas(empresaId, numeroDocumentoIdentidad);
			dispatch({ type: "SET_CONSULTAS", payload: consultas });
		} catch (error) {
			console.error(error);
		} finally {
			dispatch({ type: "FINALIZAR" });
		}
	};

	const getUrlPdfSolicitudAbsentismo = async (empresaId, solicitudId, plataforma = Plataforma.WEB) => {
		try {
			dispatch({ type: "INICIAR" });
			const respuesta = await SolicitudAusenciaService.obtenerUrl(empresaId, solicitudId, plataforma);
			dispatch({ type: "SET_URL_PDF", payload: respuesta.data.urlSolicitud });
			// dispatch({
			// 	type: "SET_DOCUMENTO_SELECCIONADO",
			// 	payload: respuesta.documento,
			// });
			// dispatch({
			// 	type: "SET_DOCUMENTOS",
			// 	payload: state.documentos.map((documento) => {
			// 		if (documento.id === respuesta.documento.id) {
			// 			return respuesta.documento;
			// 		}
			// 		return documento;
			// 	}),
			// });

			//return respuesta;
		} catch (error) {
			console.error(error);
		} finally {
			dispatch({ type: "FINALIZAR" });
		}
	};

	const iniciarCargaPdf = () => {
		dispatch({ type: "INICIAR_CARGA_PDF" });
	};

	const finalizarCargaPdf = () => {
		dispatch({ type: "FINALIZAR_CARGA_PDF" });
	};

	const mostrarModalFirmar = () => {
		dispatch({ type: "MOSTRAR_MODAL_FIRMAR" });
	};

	const mostrarModalFirmarFormulario = (datosFormulario) => {
		dispatch({ type: "MOSTRAR_MODAL_FIRMAR" });
		dispatch({ type: "SET_DATOS_FORMULARIO", payload: datosFormulario });
	};

	const cerrarModalFirmar = () => {
		dispatch({ type: "CERRAR_MODAL_FIRMAR" });
	};

	const mostrarModalExito = () => {
		dispatch({ type: "MOSTRAR_MODAL_EXITO" });
	};

	const cerrarModalExito = () => {
		dispatch({ type: "CERRAR_MODAL_EXITO" });
	};

	const cerrarModalConsultar = () => {
		dispatch({ type: "CERRAR_MODAL_CONSULTAR" });
	};

	const mostrarModalConsultar = () => {
		dispatch({ type: "MOSTRAR_MODAL_CONSULTAR" });
	};

	const mostrarVisorPdf = (documentoId) => {
		dispatch({ type: "MOSTRAR_VISOR_PDF" });
		dispatch({
			type: "SET_DOCUMENTO_SELECCIONADO_ID",
			payload: documentoId,
		});
	};

	const cerrarVisorPdf = () => {
		dispatch({ type: "CERRAR_VISOR_PDF" });
	};

	const mostrarVisorFormulario = (documentoId) => {
		dispatch({ type: "MOSTRAR_VISOR_FORMULARIO" });
		dispatch({
			type: "SET_DOCUMENTO_SELECCIONADO_ID",
			payload: documentoId,
		});
	};

	const cerrarVisorFormulario = () => {
		dispatch({ type: "CERRAR_VISOR_FORMULARIO" });
	};

	const mostrarModalCompletarFormulario = (documento) => {
		dispatch({ type: "MOSTRAR_MODAL_COMPLETAR_FORMULARIO" });
		dispatch({ type: "SET_DOCUMENTO_SELECCIONADO", payload: documento });
	};

	const cerrarModalCompletarFormulario = () => {
		dispatch({ type: "CERRAR_MODAL_COMPLETAR_FORMULARIO" });
	};

	const setBlobPdf = (data) => {
		dispatch({ type: "SET_BLOB_PDF", payload: data });
	};

	const setMensajeError = (mensaje) => {
		dispatch({ type: "SET_MENSAJE_ERROR", payload: mensaje });
	};

	const setReloadPdf = (data) => {
		dispatch({ type: "SET_RELOAD_PDF", payload: data });
	};

	const mostrarModalUpload = (documentoId) => {
		dispatch({ type: "MOSTRAR_MODAL_UPLOAD" });
		dispatch({
			type: "SET_DOCUMENTO_SELECCIONADO_ID",
			payload: documentoId,
		});
	};

	const cerrarModalUpload = () => {
		dispatch({ type: "CERRAR_MODAL_UPLOAD" });
	};

	return (
		<MisDocumentosContext.Provider
			value={{
				reloadPdf: state.reloadPdf,
				documentoSeleccionadoId: state.documentoSeleccionadoId,
				documentos: state.documentos,
				loading: state.loading,
				loadingPdf: state.loadingPdf,
				documentoSeleccionado: state.documentoSeleccionado,
				urlPdf: state.urlPdf,
				visibleModalCompletarFormulario: state.visibleModalCompletarFormulario,
				visibleVisorPdf: state.visibleVisorPdf,
				visibleVisorFormulario: state.visibleVisorFormulario,
				visibleModalFirmar: state.visibleModalFirmar,
				visibleModalExito: state.visibleModalExito,
				visibleModalConsultar: state.visibleModalConsultar,
				mensajeError: state.mensajeError,
				blobPdf: state.blobPdf,
				datosFormulario: state.datosFormulario,
				consultas: state.consultas,
				visibleModalUpload: state.visibleModalUpload,
				setDocumentoSeleccionado,
				getDocumentos,
				getUrlPdf,
				getUrlPdfSolicitudAbsentismo,
				iniciarCargaPdf,
				finalizarCargaPdf,
				mostrarVisorPdf,
				mostrarVisorFormulario,
				mostrarModalFirmar,
				mostrarModalFirmarFormulario,
				mostrarModalExito,
				mostrarModalConsultar,
				cerrarModalFirmar,
				cerrarModalExito,
				cerrarModalConsultar,
				cerrarVisorPdf,
				cerrarVisorFormulario,
				firmarFormulario,
				firmarDocumento,
				firmarFlujoFirma,
				setMensajeError,
				setBlobPdf,
				setReloadPdf,
				obtenerDocumento,
				mostrarModalCompletarFormulario,
				cerrarModalCompletarFormulario,
				getConsultas,
				mostrarModalUpload,
				cerrarModalUpload
			}}
		>
			{children}
		</MisDocumentosContext.Provider>
	);
}
