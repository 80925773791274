import React, { useContext, useRef } from "react";
import { useHistory } from "react-router";
import { ArrowLeftOutlined, WarningOutlined } from "@ant-design/icons";
import { useFormularioPdf } from "./hooks";
import { Document } from "react-pdf";
import PaginaPdf from "./componentes/PaginaPdf";
import { useAtom } from "jotai";
import { erroresFormularioAtom, formularioSeleccionadoAtom } from "../MisDocumentosMovilStorage";
import { Button, Alert, Popover } from "antd";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import SignatureModal from "./componentes/SignatureModal";
import Errores from "./componentes/Errores";
import { MisDocumentosContext } from "context/v3/MisDocumentosContext";
import { MisDocumentosContextProvider } from "context/v3/MisDocumentosContextProvider";
import { ModalFirmar } from "../../ModalFirmar";
import { usePlataforma } from "hooks";
import { ModalExito } from "../../ModalExito";
import BarraHerramientas from "./componentes/BarraHerramientas";

const VisorFormularioPdfV3 = () => {
	const history = useHistory();
	const documentWrapperRef = useRef(null);
	// const [scale, setScale] = useState(1);
	// const [zoom, setZoom] = useAtom(zoomVisorFormularioAtom);
	const {
		urlPdf,
		totalPaginas,
		componentes,
		variablesFormulario,
		datosFormulario,
		motivoRechazo,
		onCargaExitosa,
		onCargaError,
		urlFirmaHolografa,
		urlHuellaDactilar,
		loadingPdf
	} = useFormularioPdf();

	const { plataforma } = usePlataforma();

	const frmVisor = useForm();

	const onClickRetornar = () => {
		history.push("/v3/mis-pendientes");
	};

	const divRef = useRef();

	const ContenidoMotivoRechazo = () => {
		return (
			<React.Fragment>
				<div style={{ backgroundColor: "#fffbe6", border: "1px solid #ffe58f", padding: "4px 10px" }}>
					<span style={{ display: "block", fontWeight: "bold" }}>
						<WarningOutlined style={{ color: "#faad14" }} /> Observaciones:
					</span>
					<span>{motivoRechazo}</span>
				</div>
			</React.Fragment>
		);
	};

	return (
		<MisDocumentosContextProvider>
			<FormProvider {...frmVisor}>
				<div ref={documentWrapperRef} className="new-container-movil">
					<div className="container-movil-pdf-header">
						<div style={{ width: "30%" }} onClick={onClickRetornar}>
							<ArrowLeftOutlined style={{ fontSize: "20px", marginRight: "8px" }} />
							Retornar
						</div>
						{motivoRechazo && (
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									width: "70%",
									paddingRight: "5px",
									fontSize: "12px",
									fontWeight: "normal",
								}}
							>
								<Popover
									placement="bottomRight"
									// title={"Observaciones"}
									content={ContenidoMotivoRechazo}
									trigger="click"
								>
									<Alert message="Ver observaciones" type="warning" showIcon />
								</Popover>
							</div>
						)}
					</div>

					<BarraHerramientas />

					<Errores />

					<div className="formulario-container-movil-body">
						<div
							ref={divRef}
							className="formulario-container-movil-body-pdf"
							style={{ height: "calc(100vh - 150)", overflowY: "scroll" }}
						>
							{urlPdf && (
								<Document
									file={urlPdf}
									onLoadSuccess={onCargaExitosa}
									onLoadError={onCargaError}
									loading={null}
								>
									{Array.from(new Array(totalPaginas), (el, index) => (
										<PaginaPdf
											refContainer={documentWrapperRef}
											key={`page_${index + 1}`}
											numeroPagina={index + 1}
											componentes={componentes}
											variablesFormulario={variablesFormulario}
											datosFormulario={datosFormulario}
											urlFirmaHolografa={urlFirmaHolografa}
											urlHuellaDactilar={urlHuellaDactilar}
										/>
									))}
								</Document>
							)}
						</div>
					</div>
					<div className="container-movil-pdf-footer">
						<BotonFirmarDocumento loadingPdf={loadingPdf} />
					</div>
				</div>
				<SignatureModal />
			</FormProvider>
			<ModalFirmar plataforma={plataforma} />
			<ModalExito />
		</MisDocumentosContextProvider>
	);
};

const BotonFirmarDocumento = ({ loadingPdf }) => {
	const { mostrarModalFirmarFormulario, setDocumentoSeleccionado } = useContext(MisDocumentosContext);
	const [, setErroresFormulario] = useAtom(erroresFormularioAtom);
	const { handleSubmit } = useFormContext();
	const [formularioSeleccionado] = useAtom(formularioSeleccionadoAtom);

	const onSubmit = (datosFormulario) => {
		setDocumentoSeleccionado(formularioSeleccionado);
		mostrarModalFirmarFormulario(datosFormulario);
	};

	const onError = (errores) => {
		setErroresFormulario({ ...errores });
	};

	const onClickBtnFirmar = async () => {
		handleSubmit(onSubmit, onError)();
	};

	return (
		<Button type="primary" onClick={onClickBtnFirmar} block disabled={loadingPdf} loading={loadingPdf}>
			{formularioSeleccionado?.requiereFirmaColaborador ? "Firmar Documento" : "Confirmar Visualización"}
		</Button>
	);
};

export default VisorFormularioPdfV3;
