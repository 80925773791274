import React from 'react';
import { useMediaQuery } from 'react-responsive';
// import { MiPortalPageWeb } from './componentes/web';
import { Provider as JotaiProvider } from "jotai";
// import { MiPortalMovil } from './componentes/movil';
import ListarCumpleanios from './ListarCumpleanios';
import { ListarCumpleaniosMovil } from 'pages/v3/mi-portal/componentes/movil/componentes/Cumpleanios/Listar';

const ListarCumpleaniosPage = () => {
    const esMovil = useMediaQuery({ query: "(max-width: 860px)" });
    const esDesktop = useMediaQuery({ query: "(min-width: 860px)" });
    return (
        <JotaiProvider>
            {esDesktop && <ListarCumpleanios />}
            {esMovil && <ListarCumpleaniosMovil />}
        </JotaiProvider>
    );
};

export default ListarCumpleaniosPage;