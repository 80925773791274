import React, { useState, useEffect } from "react";
import { TipoControl } from "enum";
import { Page } from "react-pdf";
import { Input, Button, Select, Upload } from "antd";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useAtom, useAtomValue } from "jotai";
import {
	componentSelectedAtom,
	signatureModalVisibleAtom,
	signaturesMetadataAtom,
	urlFirmaHolografaAtom,
	urlHuellaDactilarAtom,
	urlFotoAtom,
	zoomVisorFormularioAtom,
	visibilidadComponentesAtom,
} from "pages/v3/mis-pendientes/componentes/MisDocumentosWeb/MisDocumentosWebStorage";
//import moment from "moment/locale/fr";
import moment from "moment";
import "moment/locale/es";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { SignaturePerson } from "components/common/VisorFormulario/constantes";
import produce from "immer";
import html2canvas from "html2canvas";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";

const PaginaPdf = ({ numeroPagina, componentes, variablesFormulario, datosFormulario }) => {
	moment.locale("es");
	console.log("locale: ", moment.locale());
	const [zoom] = useAtom(zoomVisorFormularioAtom);

	return (
		<React.Fragment>
			<div
				style={{
					position: "relative",
				}}
			>
				<Page pageNumber={numeroPagina} loading={null} scale={zoom} />

				<div id="componentes" style={{ zoom: zoom }}>
					{componentes?.length > 0 &&
						componentes
							.filter((componente) => componente.overlay.page === numeroPagina)
							.map((componente) => {
								return (
									<ControlPdf
										key={componente.key}
										componente={componente}
										variables={variablesFormulario}
										datosFormulario={datosFormulario}
									/>
								);
							})}
				</div>
			</div>
			<SeparadorPaginas />
		</React.Fragment>
	);
};

const ControlPdf = ({ componente, variables, datosFormulario }) => {
	const {
		control,
		setValue,
		register,
		formState: { errors },
	} = useFormContext();
	const [, setSignatureModalVisible] = useAtom(signatureModalVisibleAtom);
	const [, setComponentSelected] = useAtom(componentSelectedAtom);
	const [signaturesMetadata, setSignaturesMetadata] = useAtom(signaturesMetadataAtom);
	const [signature, setSignature] = useState(undefined);
	const [imagen, setImagen] = useState(undefined);
	const urlFirmaHolografa = useAtomValue(urlFirmaHolografaAtom);
	const urlHuellaDactilar = useAtomValue(urlHuellaDactilarAtom);
	const urlFoto = useAtomValue(urlFotoAtom);
	const [visibilidadComponentes, setVisibilidadComponentes] = useAtom(visibilidadComponentesAtom);

	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;

	const watchControl = useWatch({
		name: componente.type === TipoControl.RADIO_BUTTON ? componente.name : componente.key,
	});

	useEffect(() => {
		const inicializar = () => {
			if (componente.type === TipoControl.RADIO_BUTTON && watchControl !== "") {
				setVisibilidadComponentes({ ...visibilidadComponentes, [componente.name]: watchControl });
			} else if (componente.type === TipoControl.CHECKBOX && watchControl !== "") {
				const valor = watchControl !== "" && watchControl !== undefined ? "checked" : undefined;
				setVisibilidadComponentes({
					...visibilidadComponentes,
					[componente.key]: valor,
				});
			}
		};

		inicializar();
	}, [watchControl]);

	useEffect(() => {
		const cargarHuellaDactilar = () => {
			if (
				urlHuellaDactilar &&
				componente.type === TipoControl.IMAGEN &&
				componente.defaultValue === "HUELLA_DACTILAR"
			) {
				fetch(urlHuellaDactilar)
					.then((response) => response.blob())
					.then((blob) => {
						const reader = new FileReader();
						reader.readAsDataURL(blob);
						reader.onload = () => {
							setValue(nombreControl, reader?.result);
							setImagen({
								key: componente.key,
								base64: reader.result,
							});
						};
					})
					.catch((error) => {
						console.log("no se pudo cargar la huella dactilar", error);
					});
			}
		};
		cargarHuellaDactilar();
	}, [urlHuellaDactilar]);

	useEffect(() => {
		const cargarFoto = () => {
			if (urlFoto && componente.type === TipoControl.IMAGEN && componente.defaultValue === "FOTO") {
				fetch(urlFoto)
					.then((response) => response.blob())
					.then((blob) => {
						const reader = new FileReader();
						reader.readAsDataURL(blob);
						reader.onload = () => {
							setValue(nombreControl, reader?.result);
							setImagen({
								key: componente.key,
								base64: reader.result,
							});
						};
					})
					.catch((error) => {
						console.log("no se pudo cargar la foto", error);
					});
			}
		};
		cargarFoto();
	}, [urlFoto]);

	useEffect(() => {
		const cargarFirmaHolografa = () => {
			if (
				urlFirmaHolografa &&
				componente.type === TipoControl.SIGNATURE &&
				(componente.signaturePerson === SignaturePerson.COLABORADOR
					|| componente.signaturePerson === SignaturePerson.PERSONALIZADO)
			) {
				fetch(urlFirmaHolografa)
					.then((response) => response.blob())
					.then((blob) => {
						const reader = new FileReader();
						reader.readAsDataURL(blob);
						reader.onload = () => {
							setSignaturesMetadata(
								produce((draft) => {
									const signature = draft.find((s) => s.key === componente.key);
									if (signature) {
										signature.base64 = reader.result;
									} else {
										draft.push({
											key: componente.key,
											base64: reader.result,
										});
									}
								})
							);
						};
					})
					.catch((error) => {
						console.log("no se pudo cargar la url de firma hológrafa", error);
					});
			}
		};

		cargarFirmaHolografa();
	}, [urlFirmaHolografa]);

	const getNombreControl = () => {
		let nombreControl = componente.key;
		if (componente.groupBy) {
			nombreControl = `${componente.groupBy.table}.${componente.groupBy.rowNumber - 1}.${componente.groupBy.column
				}`;
		} else {
			if (componente.type === TipoControl.RADIO_BUTTON) {
				nombreControl = componente.name;
			} else if (componente.type === TipoControl.SIGNATURE) {
				nombreControl = componente.key + "_signature_base64";
			} else if (componente.type === TipoControl.IMAGEN) {
				nombreControl = componente.key + "_imagen_base64";
			} else {
				nombreControl = componente.key;
			}
		}
		return nombreControl;
	};

	const getClassName = (nombreControl) => {
		let className = "";
		if (errors) {
			if (componente.type === TipoControl.CAJA_TEXTO) {
				if (componente.groupBy) {
					className = "formulario-input";
					const errores = errors[componente.groupBy.table];
					if (
						errores &&
						errores[componente.groupBy.rowNumber - 1] &&
						errores[componente.groupBy.rowNumber - 1][componente.groupBy.column]
					) {
						className = "formulario-input-error";
					}
				} else {
					className = errors[nombreControl] ? "formulario-input-error" : "formulario-input";
				}
			} else if (componente.type === TipoControl.LISTA) {
				if (componente.groupBy) {
					className = "formulario-list";
					const errores = errors[componente.groupBy.table];
					if (
						errores &&
						errores[componente.groupBy.rowNumber - 1] &&
						errores[componente.groupBy.rowNumber - 1][componente.groupBy.column]
					) {
						className = "formulario-list-error";
					}
				} else {
					className = errors[nombreControl] ? "formulario-list-error" : "formulario-list";
				}
			} else if (componente.type === TipoControl.CHECKBOX) {
				if (componente.groupBy) {
					className = "formulario-control-container";
					const errores = errors[componente.groupBy.table];
					if (
						errores &&
						errores[componente.groupBy.rowNumber - 1] &&
						errores[componente.groupBy.rowNumber - 1][componente.groupBy.column]
					) {
						className = "formulario-control-container-error";
					}
				} else {
					className = errors[nombreControl]
						? "formulario-control-container-error"
						: "formulario-control-container";
				}
			} else if (componente.type === TipoControl.RADIO_BUTTON) {
				if (componente.groupBy) {
					className = "formulario-control-container";
					const errores = errors[componente.groupBy.table];
					if (
						errores &&
						errores[componente.groupBy.rowNumber - 1] &&
						errores[componente.groupBy.rowNumber - 1][componente.groupBy.column]
					) {
						className = "formulario-control-container-error";
					}
				} else {
					className = errors[nombreControl]
						? "formulario-control-container-error"
						: "formulario-control-container";
				}
			} else if (componente.type === TipoControl.SIGNATURE) {
				className = errors[nombreControl] ? "formulario-signature-button-error" : "formulario-signature-button";
			} else if (componente.type === TipoControl.IMAGEN) {
				className = errors[nombreControl] ? "formulario-file-error" : "formulario-file";
			}
		}
		return className;
	};

	let nombreControl = getNombreControl();
	let className = getClassName(nombreControl);

	useEffect(() => {
		if (componente.type === TipoControl.SIGNATURE) {
			const signature = signaturesMetadata.find((s) => s.key === componente.key);
			setSignature(signature);
			setValue(nombreControl, signature?.base64);
		}
	}, [signaturesMetadata]);

	useEffect(() => {
		const loadSignature = async () => {
			if (componente.type === TipoControl.SIGNATURE) {
				if (componente.signatureType === "IMAGEN_Y_DESCRIPCION") {
					const divElement = document.getElementById("signature" + nombreControl);

					const scale = 2;
					const canvas = await html2canvas(divElement, { scale });

					const base64Image = canvas.toDataURL("image/png");

					if (base64Image !== "data:,") {
						setValue(nombreControl, base64Image);
					}
				}
			}
		};

		loadSignature();
	}, [signature]);

	const getMensajeRequerido = () => {
		if (componente.required) {
			if (componente.groupBy) {
				return "El campo " + componente.label + " de la tabla " + componente.groupBy.table + " es requerido.";
			}
			return "El campo " + componente.label + " es requerido.";
		}
		return false;
	};

	const reglas = {
		required: getMensajeRequerido(),
	};

	if (componente.type === TipoControl.CAJA_TEXTO) {
		var valorInicial = "";
		if (componente.defaultValue) {
			// if (componente.formatDefaultValue) {
			// 	valorInicial = moment(variables[componente.defaultValue], "DD/MM/YYYY").format(
			// 		componente.formatDefaultValue
			// 	);
			// } else {
			// 	valorInicial = variables[componente.defaultValue];
			// }
			if (componente.defaultValue === "${PERSONALIZADO}") {
				valorInicial = reemplazarVariables(componente.customDefaultValue, variables);
			} else if (componente.formatDefaultValue) {
				valorInicial = moment(variables[componente.defaultValue], "DD/MM/YYYY").format(
					componente.formatDefaultValue
				);
			} else {
				valorInicial = variables[componente.defaultValue];
			}
		} else if (datosFormulario) {
			valorInicial = datosFormulario[componente.key];
		}

		// if (
		// 	componente.condicional?.control !== null &&
		// 	componente.condicional?.control !== undefined &&
		// 	(visibilidadComponentes[componente.condicional?.control] === undefined ||
		// 		visibilidadComponentes[componente.condicional?.control].toLowerCase() !==
		// 		componente.condicional.valor.toLowerCase())
		// ) {
		// 	return "";
		// }
		if (debeOcultarseComponente(componente, visibilidadComponentes)) {
			return "";
		}

		return (
			<Controller
				name={nombreControl}
				control={control}
				rules={reglas}
				defaultValue={valorInicial}
				render={({ field }) => (
					<Input
						className={className}
						style={{
							top: componente.overlay.top,
							left: componente.overlay.left,
							height: componente.overlay.height,
							width: componente.overlay.width,
							fontSize: "12px",
						}}
						readOnly={componente.readOnly}
						maxLength={componente.maxLength && componente.maxLength > 0 ? componente.maxLength : undefined}
						{...field}
					/>
				)}
			/>
		);
	} else if (componente.type === TipoControl.LISTA) {
		var valorInicial = "";
		if (datosFormulario) {
			valorInicial = datosFormulario[componente.key];
		}
		if (debeOcultarseComponente(componente, visibilidadComponentes)) {
			return "";
		}
		return (
			<Controller
				name={nombreControl}
				control={control}
				rules={reglas}
				defaultValue={valorInicial}
				render={({ field }) => (
					<select
						name="select"
						className={className}
						style={{
							top: componente.overlay.top,
							left: componente.overlay.left,
							height: componente.overlay.height,
							width: componente.overlay.width,
							fontSize: componente.overlay.height <= 12 ? "9px" : "12px",
						}}
						{...field}
					>
						<option value=""></option>
						{componente.items.map((item) => {
							return (
								<option key={item.key} value={item.key}>
									{item.value}
								</option>
							);
						})}
					</select>
				)}
			/>
		);
	} else if (componente.type === TipoControl.CHECKBOX) {
		var valorInicial = false;
		if (datosFormulario) {
			valorInicial = datosFormulario[componente.key] ? true : false;
		}
		if (debeOcultarseComponente(componente, visibilidadComponentes)) {
			return "";
		}
		return (
			<Controller
				name={nombreControl}
				control={control}
				rules={reglas}
				// defaultValue={valorInicial ? true : false}
				render={({ field }) => (
					<div
						className={className}
						style={{
							top: componente.overlay.top,
							left: componente.overlay.left,
							height: componente.overlay.height,
							width: componente.overlay.width,
						}}
					>
						<input
							className="formulario-checkbox"
							type="checkbox"
							// defaultChecked={valorInicial}
							onChange={(e) => field.onChange(e.target.checked ? componente.value : undefined)}
						/>
					</div>
				)}
			/>
		);
	} else if (componente.type === TipoControl.RADIO_BUTTON) {
		//valorInicial = "";
		var valorInicial = "";
		if (componente.defaultValue) {
			valorInicial = variables[componente.defaultValue]?.toUpperCase();
		} else if (datosFormulario) {
			valorInicial = datosFormulario[componente.name]?.toUpperCase();
		}
		if (debeOcultarseComponente(componente, visibilidadComponentes)) {
			return "";
		}
		return (
			<Controller
				key={componente.key}
				name={nombreControl}
				control={control}
				rules={reglas}
				defaultValue={valorInicial}
				render={({ field }) => (
					<div
						className={className}
						style={{
							top: componente.overlay.top,
							left: componente.overlay.left,
							height: componente.overlay.height,
							width: componente.overlay.width,
						}}
					>
						<input
							className="formulario-radio-button"
							{...field}
							type="radio"
							// checked={valorInicial === componente.value}
							// name={nombreControl}
							value={componente.value}
							defaultChecked={valorInicial === componente?.value.toUpperCase()}
						/>
					</div>
				)}
			/>
		);
	} else if (componente.type === TipoControl.SIGNATURE) {
		const onClickBtnFirmar = () => {
			setComponentSelected(componente);
			if (isExplomin) {
				setSignatureModalVisible(false);
			} else {
				setSignatureModalVisible(true);
			}
		};

		const isFirmaColaborador =
			componente.signaturePerson === SignaturePerson.COLABORADOR
			|| componente.signaturePerson === null
			|| componente.signaturePerson == SignaturePerson.PERSONALIZADO;

		return (
			<React.Fragment>
				<input {...register(nombreControl, reglas)} type="hidden" className="formulario-hidden" />
				<div
					id={"signature" + nombreControl}
					className="formulario-signature-container"
					style={{
						top: componente.overlay.top,
						left: componente.overlay.left,
						height: componente.overlay.height,
						width: componente.overlay.width,
						display: signature ? "flex" : "none",
						maxWidth: componente.overlay.width,
						maxHeight: componente.overlay.height,
					}}
					onClick={onClickBtnFirmar}
				>
					<img
						style={{
							flex: "1",
							width: isFirmaColaborador ? "100%" : "50%",
						}}
						src={signature?.base64}
					/>
					{!isFirmaColaborador && (
						<div
							style={{
								flex: "1",
								fontSize: "0.5em",
								display: "flex",
								justifyContent: "center",
								flexDirection: "column",
							}}
						>
							Firmado por: {getUsuarioAutenticado().nombreCompleto}
							<br />
							Fecha: {moment().format("DD/MM/YYYY")}
						</div>
					)}
				</div>
				<Button
					className={className}
					onClick={onClickBtnFirmar}
					style={{
						top: componente.overlay.top,
						left: componente.overlay.left,
						height: componente.overlay.height,
						width: componente.overlay.width,
						display: signature?.base64 ? "none" : "inherit",
					}}
				>
					<span style={{ fontWeight: "bold", fontSize: "12px" }}>Firmar aquí</span>
				</Button>
			</React.Fragment>

			// <React.Fragment>
			// 	<input {...register(nombreControl, reglas)} type="hidden" className="formulario-hidden" />
			// 	<div
			// 		className="formulario-signature-container"
			// 		style={{
			// 			top: componente.overlay.top,
			// 			left: componente.overlay.left,
			// 			height: componente.overlay.height,
			// 			width: componente.overlay.width,
			// 			display: signature ? "flex" : "none",
			// 		}}
			// 		onClick={onClickBtnFirmar}
			// 	>
			// 		<img src={signature?.base64} />
			// 	</div>
			// 	<Button
			// 		className={className}
			// 		onClick={onClickBtnFirmar}
			// 		style={{
			// 			top: componente.overlay.top,
			// 			left: componente.overlay.left,
			// 			height: componente.overlay.height,
			// 			width: componente.overlay.width,
			// 			display: signature?.base64 ? "none" : "inherit",
			// 		}}
			// 	>
			// 		<span style={{ fontWeight: "bold", fontSize: "12px" }}>Firmar aquí</span>
			// 	</Button>
			// </React.Fragment>
		);
	} else if (componente.type === TipoControl.IMAGEN) {
		const onChangeImagen = (e) => {
			if (e.target.files[0] !== undefined) {
				let reader = new FileReader();
				reader.readAsDataURL(e.target.files[0]);
				reader.onload = () => {
					setValue(nombreControl, reader?.result);
					setImagen({
						key: componente.key,
						base64: reader.result,
					});
				};
			}
		};

		if (debeOcultarseComponente(componente, visibilidadComponentes)) {
			return "";
		}

		return (
			<React.Fragment>
				<input {...register(nombreControl, reglas)} type="hidden" className="formulario-hidden" />

				<label
					className={className}
					style={{
						top: componente.overlay.top,
						left: componente.overlay.left,
						height: componente.overlay.height,
						width: componente.overlay.width,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						cursor: "pointer",
					}}
				>
					{imagen?.base64 && (
						<img
							style={{
								top: componente.overlay.top,
								left: componente.overlay.left,
								height: componente.overlay.height,
								width: componente.overlay.width,
								display: imagen ? "flex" : "none",
							}}
							src={imagen?.base64}
						/>
					)}
					{!imagen?.base64 && (
						<React.Fragment>
							<UploadOutlined style={{ fontSize: "16px" }} />
							<span style={{ fontSize: "10px" }}>Subir imagen</span>
						</React.Fragment>
					)}
					{!isExplomin && (
						<input style={{ display: "none" }} type="file" onChange={onChangeImagen} />
					)}
				</label>
			</React.Fragment>
		);
	} else if (componente.type === TipoControl.ETIQUETA) {
		valorInicial = "";
		if (componente.defaultValue) {
			if (componente.defaultValue === "${PERSONALIZADO}") {
				valorInicial = reemplazarVariables(componente.customDefaultValue, variables);
			} else if (componente.formatDefaultValue) {
				valorInicial = moment(variables[componente.defaultValue], "DD/MM/YYYY").format(
					componente.formatDefaultValue
				);
			} else {
				valorInicial = variables[componente.defaultValue];
			}
		} else if (datosFormulario) {
			valorInicial = datosFormulario[componente.key];
		}

		if (debeOcultarseComponente(componente, visibilidadComponentes)) {
			return "";
		}
		return (
			<Controller
				name={nombreControl}
				control={control}
				rules={reglas}
				defaultValue={valorInicial}
				render={({ field }) => (
					<Input.TextArea
						className="formulario-label"
						style={{
							top: componente.overlay.top,
							left: componente.overlay.left,
							height: componente.overlay.height,
							width: componente.overlay.width,
							fontSize: "12px",
							resize: 'none',
						}}
						readOnly={true}
						bordered={false}
						{...field}
					/>
				)}
			/>
		);
	}

	return "";
};

const SeparadorPaginas = () => {
	return (
		<div style={{ width: "100%", position: "relative" }}>
			<div style={{ height: "2em" }}></div>
		</div>
	);
};

function reemplazarVariables(texto, variables) {
	return texto.replace(/(\$\{[^}]+\})/g, (match, variable) => {
		if (variables.hasOwnProperty(variable)) {
			return variables[variable];
		}
		return match;
	});
}

const debeOcultarseComponente = (componente, visibilidadComponentes) => {
	if (
		componente.condicional?.control !== null &&
		componente.condicional?.control !== undefined &&
		componente.condicional?.accion === "show" &&
		(visibilidadComponentes[componente.condicional?.control] === undefined
			|| visibilidadComponentes[componente.condicional?.control].toLowerCase() !== componente.condicional.valor.toLowerCase())
	) {
		return true;
	}

	if (
		componente.condicional?.control !== null &&
		componente.condicional?.control !== undefined &&
		componente.condicional?.accion === "hidden" &&
		(visibilidadComponentes[componente.condicional?.control] !== undefined
			&& visibilidadComponentes[componente.condicional?.control].toLowerCase() === componente.condicional.valor.toLowerCase())
	) {
		return true;
	}
	return false
}

export default PaginaPdf;
